export function isNumber(x: any): x is number {
  return Number.isFinite(x);
}

export function isString(x: any): x is string {
  return typeof x === 'string';
}

export function isStringOrNumber(x: any): x is string | number {
  return isString(x) || isNumber(x);
}

export function isArrayOfStrings(value: unknown): value is string[] {
  return Array.isArray(value) && value.every(isString);
}
