import { Skeleton } from 'antd';
import { LineChartOutlined } from '@ant-design/icons';

interface Props {
  height?: number;
}

export const InitiativeGraphSkeleton = ({ height = 50 }: Props) => {
  return (
    <div className="flx flx--column">
      <Skeleton.Node style={{ height, width: '100%' }} active>
        <LineChartOutlined style={{ fontSize: 40, color: '#bfbfbf' }} />
      </Skeleton.Node>
      <div className="flx flx--jc-space-between txt--secondary mt">
        <Skeleton paragraph={false} title={{ width: 40 }} />
        <div className="flx flx--gap">
          <Skeleton paragraph={false} title={{ width: 80 }} />
          <Skeleton paragraph={false} title={{ width: 80 }} />
        </div>
        <Skeleton
          paragraph={false}
          title={{ width: 40, style: { marginLeft: 'auto' } }}
        />
      </div>
    </div>
  );
};
