import React, { Suspense } from 'react';
import { Modal } from 'antd';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage } from '../../../../../../../../../components/ErrorPage';
import { ReactivateMilestoneModalContent } from './ReactivateMilestoneModalContent';

interface ReactivateMilestoneModalProps {
  milestoneId: string | undefined;
  visible: boolean;
  onClose: () => void;
}

export const ReactivateMilestoneModal: React.FC<
  ReactivateMilestoneModalProps
> = ({ milestoneId, visible, onClose }) => {
  return (
    <Modal open={visible} footer={null} onCancel={onClose}>
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <Suspense fallback={<ReactivateMilestoneModalContent.Skeleton />}>
          {milestoneId && (
            <ReactivateMilestoneModalContent
              milestoneId={milestoneId}
              onClose={onClose}
            />
          )}
        </Suspense>
      </ErrorBoundary>
    </Modal>
  );
};
