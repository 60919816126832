import { useTranslation } from 'react-i18next';
import { MilestoneActivitiesTableSkeleton } from '../../MilestoneActivitiesTable.skeleton';

export const MilestoneCardActivityTabSkeleton = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="mt--l">
        <h4 className="mb">{t('MilestoneCardActivityTab.yourTeam')}</h4>
        <MilestoneActivitiesTableSkeleton numberOfActivities={2} />
      </div>
      <div className="mt--xl">
        <h4 className="mb">{t('MilestoneCardActivityTab.otherTeams')}</h4>
        <MilestoneActivitiesTableSkeleton numberOfActivities={4} />
      </div>
    </div>
  );
};
