import { useTranslation } from 'react-i18next';
import { UpdateFinancialPotentialForm_InitiativeFragment } from '../../../../../../generated/graphql';
import { gql } from '@apollo/client';
import { Form, InputNumber, Space, FormInstance, Input } from 'antd';
import { z } from 'zod';

type Props = {
  initiative: UpdateFinancialPotentialForm_InitiativeFragment;
  form: FormInstance;
};

export const UpdateFinancialPotentialForm = ({ initiative, form }: Props) => {
  const { t } = useTranslation();

  return (
    <Form layout="vertical" form={form}>
      <Form.Item name="rev" hidden initialValue={initiative.rev}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item label={t('UpdateFinancialPotentialForm.profit')}>
        <Space.Compact>
          <Form.Item
            name={['profit', 'current']}
            noStyle
            initialValue={initiative.profit?.current}
          >
            <InputNumber
              style={{ width: '50%' }}
              prefix={
                <span className="bold">
                  {t('UpdateFinancialPotentialForm.current')}:
                </span>
              }
            />
          </Form.Item>
          <Form.Item
            name={['profit', 'potential']}
            noStyle
            initialValue={initiative.profit?.potential}
          >
            <InputNumber
              style={{ width: '50%' }}
              prefix={
                <span className="bold">
                  {t('UpdateFinancialPotentialForm.potential')}:
                </span>
              }
            />
          </Form.Item>
        </Space.Compact>
      </Form.Item>

      <Form.Item label={t('UpdateFinancialPotentialForm.revenue')}>
        <Space.Compact>
          <Form.Item
            name={['revenue', 'current']}
            noStyle
            initialValue={initiative.revenue?.current}
          >
            <InputNumber
              style={{ width: '50%' }}
              prefix={
                <span className="bold">
                  {t('UpdateFinancialPotentialForm.current')}:
                </span>
              }
            />
          </Form.Item>
          <Form.Item
            name={['revenue', 'potential']}
            noStyle
            initialValue={initiative.revenue?.potential}
          >
            <InputNumber
              style={{ width: '50%' }}
              prefix={
                <span className="bold">
                  {t('UpdateFinancialPotentialForm.potential')}:
                </span>
              }
            />
          </Form.Item>
        </Space.Compact>
      </Form.Item>
    </Form>
  );
};

export const financialPotentialSchema = z.object({
  rev: z.string(),
  profit: z.object({
    current: z.number().nullish(),
    potential: z.number().nullish(),
  }),
  revenue: z.object({
    current: z.number().nullish(),
    potential: z.number().nullish(),
  }),
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UPDATE_FINANCIAL_POTENTIAL_MODALCONTENT_INITIATIVE = gql`
  fragment UpdateFinancialPotentialForm_Initiative on Initiative2 {
    id
    rev
    profit {
      current
      potential
    }
    revenue {
      current
      potential
    }
  }
`;
