import { MilestoneStatus, MitemStatus } from '../generated/graphql';
import { Moment } from 'moment';
import { friendlyDate } from './dateFormats';
import dayjs, { Dayjs } from 'dayjs';

export const recordDefaultSortOrder: (MitemStatus | MilestoneStatus)[] = [
  MitemStatus.NOT_COMPLETED,
  MilestoneStatus.OVERDUE,
  MitemStatus.OVERDUE,
  MitemStatus.ACTIVE,
  MilestoneStatus.UPCOMING,
  MitemStatus.PLANNED,
  MilestoneStatus.COMPLETED,
  MitemStatus.COMPLETED,
];

interface SprintTermDto {
  id: string;
  periodData?: SprintPeriodDataDTO[];
}

const sprintTermPeriodDataDtoToSprintTermPeriodData = (
  periodData: SprintPeriodDataDTO[]
) => {
  return periodData.map(({ goal, actual, targetDate }, index) => {
    return {
      timePeriodIndex: index,
      targetDate,
      label: friendlyDate(targetDate),
      goal,
      actual: actual ?? undefined,
    };
  });
};

export const sprintTermDTOtoSprintTermData = <T extends SprintTermDto>(
  sprintTermDto: T
) => {
  const periods = sprintTermDto.periodData ?? [];

  return {
    ...sprintTermDto,
    periodData: sprintTermPeriodDataDtoToSprintTermPeriodData(periods),
  };
};

export const getTermLength = (
  startDate?: string | Dayjs | null,
  endDate?: string | Dayjs | null
) => {
  if (!startDate || !endDate) return 1;
  return dayjs(endDate).diff(startDate, 'week') + 1;
};

export const isConflictingWithTerms = (
  current: Dayjs,
  terms?: SprintTermData[]
) => {
  return terms?.some((t) => {
    const termStartDate = dayjs(t.startDate);
    const termEndDate =
      t.unpublishDate && dayjs(t.unpublishDate).isBefore(t.endDate)
        ? t.unpublishDate
        : t.endDate;

    const conflictsWithExistingTerm =
      current.isSameOrAfter(termStartDate, 'day') &&
      current.isSameOrBefore(termEndDate, 'day');

    return conflictsWithExistingTerm;
  });
};

export const isInterruptedTerm = (
  startDate?: Moment,
  endDate?: Moment,
  terms?: SprintTermData[]
) => {
  return terms?.some((t) => {
    const termStartDate = t.startDate;
    const termEndDate =
      t.unpublishDate && dayjs(t.unpublishDate).isBefore(t.endDate)
        ? t.unpublishDate
        : t.endDate;

    return (
      startDate?.isSameOrBefore(termStartDate, 'day') &&
      endDate?.isSameOrAfter(termEndDate, 'day')
    );
  });
};

export interface SprintTermData {
  sprintInterval: number;
  startDate: string;
  endDate: string;
  publishDate?: string;
  unpublishDate?: string;
  periodData?: SprintPeriodData[];
}

interface SprintPeriodDataDTO {
  targetDate: string;
  goal: number;
  actual?: number | null;
}

interface SprintPeriodData {
  timePeriodIndex: number;
  targetDate: string;
  goal?: number;
  label: string;
  actual?: number | null;
}
