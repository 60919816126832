import { Modal } from 'antd';
import { useState } from 'react';

import { Btn } from '../../../../../components/Button';
import { MilestoneCardModalContent } from '../../../../company/initiatives/initiativeDetails/progressSection/components/milestoneTable/components/milestoneActions/components/MilestoneCardModalContent';
import { ManageSearchIcon } from '../../../../../icons/ManageSearch';

interface Props {
  teamId: string;
  milestoneId: string;
}

export const SprintMilestoneTableActions = ({ teamId, milestoneId }: Props) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      <Modal
        open={showDetails}
        footer={null}
        width={800}
        onCancel={() => setShowDetails(false)}
      >
        <MilestoneCardModalContent milestoneId={milestoneId} teamId={teamId} />
      </Modal>
      <Btn
        className="txt--secondary"
        onClick={() => setShowDetails(true)}
        type="text"
        icon={<ManageSearchIcon style={{ fontSize: 20 }} />}
      />
    </>
  );
};
