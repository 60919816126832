import { Divider } from 'antd';

import { useTranslation } from 'react-i18next';

import Skeleton from 'react-loading-skeleton';
import { Btn } from '../../../../../../../../../components/Button';

export const ReactivateMilestoneModalContentSkeleton = () => {
  const { t } = useTranslation();

  return (
    <div className="text-c flx flx--column">
      <h3>{t('ReactivateMilestoneModalContent.title')}</h3>
      <Skeleton />

      <Divider />
      <div>
        <Btn loading={true} disabled type="primary">
          {t('ReactivateMilestoneModalContent.reactivate')}
        </Btn>
      </div>
    </div>
  );
};
