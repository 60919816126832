import { Tabs, TabsProps } from 'antd';
import { MilestoneDetails } from './MilestoneDetails';
import { MilestoneCardActivityTab } from './MilestoneCardActivityTab';
import { useTranslation } from 'react-i18next';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

interface Props {
  milestoneId: string;
  teamId: string;
}

export const MilestoneCardModalContent = ({ milestoneId, teamId }: Props) => {
  const { t } = useTranslation();
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('MilestoneCardModalContent.generalInfo'),
      children: (
        <ErrorBoundary FallbackComponent={MilestoneDetails.Error}>
          <Suspense fallback={<MilestoneDetails.Skeleton />}>
            <MilestoneDetails milestoneId={milestoneId} />
          </Suspense>
        </ErrorBoundary>
      ),
    },
    {
      key: '2',
      label: t('MilestoneCardModalContent.activitiesConnected'),
      children: (
        <ErrorBoundary FallbackComponent={MilestoneCardActivityTab.Error}>
          <Suspense fallback={<MilestoneCardActivityTab.Skeleton />}>
            <MilestoneCardActivityTab
              milestoneId={milestoneId}
              teamId={teamId}
            />
          </Suspense>
        </ErrorBoundary>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
};
