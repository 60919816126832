import './MilestoneActivitiesTable.less';
import { useTranslation } from 'react-i18next';
import { Alert } from 'antd';

export const MilestoneActivitiesTableError = () => {
  const { t } = useTranslation();

  return (
    <div className="MilestoneActivitiesTable">
      <table className="MilestoneActivitiesTable">
        <thead>
          <tr>
            <th className="text-l">{t('common.deadline')}</th>
            <th className="text-l">{t('common.activity')}</th>
            <th className="text-l">{t('common.team')}</th>
            <th className="text-c">{t('common.status')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <Alert
            message="Error"
            description="An error occurred while loading the data."
            type="error"
          />
        </tbody>
      </table>
    </div>
  );
};
