import Skeleton from 'react-loading-skeleton';

export const TitleSectionSkeleton = () => {
  return (
    <div className="flx--1">
      <h2>
        <Skeleton width={'30%'} />
      </h2>
    </div>
  );
};
