import { Form } from 'antd';
import { Btn } from '../../../../../../components/Button';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { atLeastOne } from '../../../../../../services/error-parser/helpers';
import { gql, useQuery } from '@apollo/client';
import { MitemFormMilestonesForTeamDocument } from '../../../../../../generated/graphql';
import { TeamMilestoneSelector } from './TeamMilestoneSelector';

interface Props {
  teamId: string;
  selectedMilestoneIds: string[];
  initialMilestoneIds?: string[];
}

export const MitemFormMilestoneFormItem = ({
  teamId,
  selectedMilestoneIds,
  initialMilestoneIds,
}: Props) => {
  const { t } = useTranslation();
  const { data } = useQuery(MitemFormMilestonesForTeamDocument, {
    variables: { teamId },
  });

  const teamMilestones = data?.milestonesForTeam.milestones ?? [];

  const selectableTeamMilestones = teamMilestones.filter(
    (ti) =>
      (ti.metadata.archived === false && ti.metadata.completedAt == null) ||
      initialMilestoneIds?.some((id) => id === ti.id)
  );

  const teamHasMilestones = atLeastOne(teamMilestones);

  return (
    <Form.Item label={t('MitemFormMilestoneFormItem.milestones')}>
      <Form.List name="supportsMilestoneIds">
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field, index) => (
                <div className="flx" key={field.key + index}>
                  <Form.Item className="flx--1" {...field}>
                    <TeamMilestoneSelector
                      milestones={selectableTeamMilestones ?? []}
                      selectedMilestoneIds={selectedMilestoneIds}
                      className={
                        index > 0 ? 'SprintKaForm__initiativeSelector' : ''
                      }
                      disabled={!teamHasMilestones}
                      placeHolder={
                        !teamHasMilestones
                          ? t('MitemFormMilestoneFormItem.noMilestones')
                          : undefined
                      }
                    />
                  </Form.Item>
                  {index > 0 && (
                    <Btn
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  )}
                </div>
              ))}
              <Form.Item>
                <Btn
                  type="link"
                  style={{ padding: 0 }}
                  icon={<PlusOutlined />}
                  disabled={
                    !teamHasMilestones ||
                    teamMilestones?.length === selectedMilestoneIds?.length
                  }
                  onClick={() => {
                    add();
                  }}
                >
                  {t('MitemFormMilestoneFormItem.addMilestone')}
                </Btn>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    </Form.Item>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MITEM_FORM_MILESTONES_FOR_TEAM = gql`
  query mitemFormMilestonesForTeam($teamId: ID!) {
    milestonesForTeam(teamId: $teamId) {
      milestones {
        id
        domainId {
          itemId
          tenantId
        }
        deadlineAt
        name
        metadata {
          archived
          completedAt
          status
          supports {
            ... on SupportedInitiative {
              type
              item {
                id
                domainId {
                  itemId
                  tenantId
                }
              }
            }
          }
        }
      }
    }
  }
`;
