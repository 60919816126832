import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import {
  GetSprintsForPlanningDocument,
  PlanViewSprintFragment,
} from '../../../../generated/graphql';
import { useEffect, useRef } from 'react';

/**
 * The sprint that is passed in cannot be historic, e.g. if index == 0 the sprint must either be currently active or upcoming
 */
const sprintMapper = (
  sprint: PlanViewSprintFragment,
  index: number,
  termHasStarted: boolean
) => ({
  ...sprint,
  isCurrent: termHasStarted && index === 0,
  isNext: termHasStarted && index === 1,
});

export const useSprintPlanningData = (teamId: string) => {
  const lastUpdated = useRef(dayjs());
  const { data, loading, error, refetch } = useQuery(
    GetSprintsForPlanningDocument,
    {
      variables: { teamId, includeUpcoming: true },
    }
  );

  useEffect(() => {
    const now = dayjs();
    const stillSameDayAsLastUpdate = lastUpdated.current.isSame(now, 'day');

    if (stillSameDayAsLastUpdate === false) {
      refetch({ teamId });
      lastUpdated.current = now;
    }
  });

  if (data?.sprints == null) return { data: null, error, loading };

  const hasCurrentSprint = data.sprints.currentSprintIndex != null;
  const currentSprintAndOnward = data.sprints.payload
    .slice(data.sprints.currentSprintIndex ?? 0)
    .map((sprint, index) => sprintMapper(sprint, index, hasCurrentSprint)); // sprintMapper sprint assumes the array starts with the currentSprint

  const timeline = data?.sprints.timeline.timeline;

  const sprintPeriodMap =
    currentSprintAndOnward &&
    timeline &&
    getSprintPeriodMap(currentSprintAndOnward, timeline);

  return {
    data: {
      currentSprintIndex: data.sprints.currentSprintIndex,
      currentSprintAndOnward,
      sprintPeriodMap,
      startDate: data.sprints.timeline.startDate,
      endDate: data.sprints.timeline.endDate,
    },
    error,
    loading,
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_SPRINTS = gql`
  query getSprintsForPlanning($teamId: ID!, $includeUpcoming: Boolean) {
    sprints(teamId: $teamId, includeUpcoming: $includeUpcoming) {
      currentSprintIndex
      payload {
        ...PlanViewSprint
      }
      timeline {
        startDate
        endDate
        timeline {
          timePeriodStartDate
          timePeriodEndDate
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PLAN_VIEW_SPRINT_FRAGMENT = gql`
  fragment PlanViewSprint on MitemSprint {
    id
    startDate
    teamId
    endDate
    locked
    finalized
    sprintNumber
  }
`;

const getSprintPeriodMap = (sprints: Sprint[], timeline: Period[]) => {
  const periodMap: Record<number, Period[]> = {};

  for (const sprint of sprints) {
    const periodsInSprint = timeline.filter(
      (period) =>
        dayjs(period.timePeriodStartDate).isSameOrAfter(
          sprint.startDate,
          'day'
        ) &&
        dayjs(period.timePeriodEndDate).isSameOrBefore(sprint.endDate, 'day')
    );

    periodMap[sprint.sprintNumber] = periodsInSprint;
  }

  return periodMap;
};

export type Sprint = ReturnType<typeof sprintMapper>;

type Period = { timePeriodStartDate: string; timePeriodEndDate: string };
