import { PlusCircleFilled } from '@ant-design/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { useGroupedAndFilteredInitiatives } from '../../../hooks/useGroupedAndFilteredInitiatives';
import { CreateInitiativesDrawer } from './initiativesPageV1/CreateInitiativesDrawer';
import { InitiativeContent } from './initiativesPageV1/InitiativeContent';
import { InitiativeNavigation } from './initiativesPageV1/InitiativeNavigation';
import { InitiativesOverview } from './initiativesPageV1/InitiativesOverview';
import { TeamSelector } from './initiativesPageV1/TeamSelector';
import { Btn } from '../../../components/Button';
import {
  Action,
  GetTenantInitiativesOldDocument,
  TeamResourceType,
} from '../../../generated/graphql';
import { usePermissionForTeams } from '../../../hooks/usePermissionForTeams';

export const InitiativesPageV1 = () => {
  const { data } = usePermissionForTeams(
    TeamResourceType.TEAM_INITIATIVE,
    Action.CREATE
  );

  const { t } = useTranslation();
  const [openCreateInitiativeDrawer, setOpenCreateInitiativeDrawer] =
    useState(false);
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const filteredByTeamId = queryParams.get('filteredByTeamId') ?? undefined;
  const matchParams = useRouteMatch<{ initiativeId?: string }>(
    `${match.path}/:initiativeId`
  );

  const hasPermission =
    data?.permittedToPerformActionForTeam.permittedResources &&
    data?.permittedToPerformActionForTeam.permittedResources.length > 0;

  const { initiativeId } = matchParams?.params ?? {};

  const {
    allInitiativesRecord,
    hierarchicalStrategicInitiatives,
    hierarchicalTeamInitiatives,
    loading,
  } = useGroupedAndFilteredInitiatives({ teamId: filteredByTeamId });

  const selectedInitiative =
    allInitiativesRecord && initiativeId
      ? allInitiativesRecord[initiativeId]
      : undefined;

  return (
    <div className="content">
      <CreateInitiativesDrawer
        visible={openCreateInitiativeDrawer}
        onClose={() => setOpenCreateInitiativeDrawer(false)}
        refetchQueries={[
          {
            query: GetTenantInitiativesOldDocument,
          },
        ]}
      />

      <div>
        <div className="flx flx--jc-space-between mt mb--xl">
          <div>
            <strong className="mr">{t('InitiativesPage.globalFilters')}</strong>
            <TeamSelector
              value={filteredByTeamId}
              onClear={() => history.push(`/company/initiatives`)}
              onChange={(teamId) =>
                teamId &&
                history.push(`/company/initiatives?filteredByTeamId=${teamId}`)
              }
              showDefaultOption={true}
            />
          </div>
          {hasPermission && (
            <Btn
              icon={<PlusCircleFilled />}
              onClick={() => setOpenCreateInitiativeDrawer(true)}
            >
              {t('common.initiative.createInitiativeHeader')}
            </Btn>
          )}
        </div>
      </div>

      <div className="divider mt mb--xl" />
      <div className="flx flx--gap--l">
        <InitiativeNavigation
          initiativeId={initiativeId}
          teamId={filteredByTeamId}
          strategicInitiatives={hierarchicalStrategicInitiatives}
          teamInitiatives={hierarchicalTeamInitiatives}
          loading={loading}
        />
        {!selectedInitiative && (
          <div className="flx--1">
            <InitiativesOverview
              teamId={filteredByTeamId}
              strategicInitiatives={hierarchicalStrategicInitiatives}
              teamInitiatives={hierarchicalTeamInitiatives}
              loading={loading}
            />
          </div>
        )}
        {selectedInitiative && (
          <InitiativeContent
            initiative={selectedInitiative}
            filteredTeamId={filteredByTeamId}
          />
        )}
      </div>
    </div>
  );
};
