import { Form, Modal } from 'antd';
import { Suspense } from 'react';
import { EditInitiativeModalContent } from './EditInitiativeModalContent';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage } from '../../../../ErrorPage';
import { gql, useMutation } from '@apollo/client';
import {
  EditInitiativeModalMutationDocument,
  SupportedEntityType,
} from '../../../../../generated/graphql';
import { utcTimeFormat } from '../../../../../services/dateFormats';
import { showNotification } from '../../../../../services/fetchNotificationProperties';
import { howweErrorParser } from '../../../../../services/howweErrorParser';
import { useTranslation } from 'react-i18next';
import { editInitiativeFormSchema } from '../../../InitiativeForm/InitiativeForm';
import { isNumber } from '../../../../../services/typeGuards';

interface Props {
  initiativeId: string;
  visible: boolean;
  onClose?: () => void;
}

export const EditInitiativeModal = ({
  visible,
  initiativeId,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [editInitiative, { loading }] = useMutation(
    EditInitiativeModalMutationDocument
  );

  const handleSubmit = () => {
    form.validateFields().then((unsafeValues) => {
      const values = editInitiativeFormSchema.parse(unsafeValues);

      const {
        rev,
        description,
        name,
        tagTitle,
        colorCode,
        iconId,
        assignedTo,
        startAt,
        endAt,
        mig,
        profit,
        revenue,
      } = values;

      const objectives = values.objectives ?? [];

      editInitiative({
        variables: {
          initiativeId,
          initiative: {
            rev,
            description,
            assignedTo: [
              {
                id: assignedTo,
              },
            ],
            objectives: objectives
              .filter((o) => !!o)
              .map((objective: string) => ({
                text: objective,
              })),
            supports: mig
              ? [
                  {
                    type: SupportedEntityType.mig,
                    item: { id: mig },
                  },
                ]
              : undefined,

            startAt: utcTimeFormat(startAt),
            endAt: endAt ? utcTimeFormat(endAt) : null,
            name: name,
            tag: {
              colorCode,
              iconId,
              title: tagTitle,
            },
            profit: isNumber(profit) ? { potential: profit } : null,
            revenue: isNumber(revenue) ? { potential: revenue } : null,
          },
        },
        onCompleted: (data) => {
          showNotification('success', {
            message: (
              <strong>
                {t('common.initiative.editSuccess', {
                  name: data.updateTenantInitiative.name,
                })}
              </strong>
            ),
          });
          onClose?.();
        },
        onError: (e) => {
          const howweErrors = howweErrorParser(e);

          showNotification('error', {
            message: (
              <strong>
                <ul>
                  {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
                </ul>
              </strong>
            ),
          });
        },
      });
    });
  };

  return (
    <Modal
      open={visible}
      width={766}
      style={{ top: 20 }}
      confirmLoading={loading}
      onOk={handleSubmit}
      onCancel={() => {
        onClose?.();
        form.resetFields();
      }}
      destroyOnClose
    >
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <Suspense fallback={<EditInitiativeModalContent.Skeleton />}>
          <EditInitiativeModalContent form={form} initiativeId={initiativeId} />
        </Suspense>
      </ErrorBoundary>
    </Modal>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EDIT_TOP_INITIATIVE = gql`
  mutation EditInitiativeModalMutation(
    $initiativeId: ID!
    $initiative: UpdateInitiative2Input!
  ) {
    updateTenantInitiative(
      initiativeId: $initiativeId
      initiative: $initiative
    ) {
      id
      name
      description
      domainId {
        itemId
      }
    }
  }
`;
