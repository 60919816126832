import { FormInstance } from 'antd';
import { MilestoneForm } from '../../../../createMilestoneButton/components/MilestoneForm';
import { gql, useSuspenseQuery } from '@apollo/client';
import { GetInitiativeMilestoneDocument } from '../../../../../../../../../../generated/graphql';
import { EditMilestoneSkeleton } from './EditMilestone.skeleton';

interface Props {
  form: FormInstance;
  milestoneId: string;
}

export const EditMilestone = ({ milestoneId, form }: Props) => {
  const { data } = useSuspenseQuery(GetInitiativeMilestoneDocument, {
    variables: { milestoneId },
    fetchPolicy: 'network-only',
  });

  const milestone = data.milestone;
  const supportedInitiatives = milestone.metadata.supports.filter(
    (s) => s.__typename === 'SupportedInitiative'
  );

  const tag = supportedInitiatives[0]?.item.tag;

  return <MilestoneForm form={form} initialValue={milestone} tag={tag} />;
};

EditMilestone.Skeleton = EditMilestoneSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EDIT_MILESTONE_SUPPORTED_INITIATIVE = gql`
  fragment EditMilestone__SupportedInitiative on SupportedInitiative {
    item {
      id
      name
      tag {
        colorCode
        iconId
        title
      }
    }
  }
`;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EDIT_MILESTONE_QUERY = gql`
  query getInitiativeMilestone($tenantId: ID, $milestoneId: ID!) {
    milestone(tenantId: $tenantId, milestoneId: $milestoneId) {
      id
      ...MilestoneFormEdit_Milestone
      metadata {
        status
        archived
        completedAt
        supports {
          ... on SupportedInitiative {
            ...EditMilestone__SupportedInitiative
          }
        }
      }
    }
  }
`;
