import Icon, {
  IconComponentProps,
} from '@ant-design/icons/lib/components/Icon';

const PublishedWithChangesSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    width="1em"
    height="1em"
  >
    <g>
      <path d="M9.167 18.291a8.331 8.331 0 0 1-2.948-.864 8.242 8.242 0 0 1-2.375-1.813 8.555 8.555 0 0 1-1.594-2.552A8.067 8.067 0 0 1 1.667 10c0-1.264.253-2.43.76-3.5A8.362 8.362 0 0 1 4.5 3.75h-2V2.083h5v5H5.834v-2.27a6.74 6.74 0 0 0-1.823 2.26A6.412 6.412 0 0 0 3.334 10c0 1.708.559 3.184 1.677 4.427 1.118 1.243 2.503 1.968 4.156 2.177v1.687Zm-.354-4.458-3.521-3.542 1.167-1.166 2.354 2.354 4.729-4.73 1.167 1.188-5.896 5.896Zm3.687 4.083v-5h1.667v2.271a6.99 6.99 0 0 0 1.823-2.27A6.348 6.348 0 0 0 16.667 10c0-1.709-.559-3.184-1.677-4.427-1.118-1.243-2.504-1.97-4.156-2.177V1.708c2.11.208 3.889 1.097 5.333 2.667 1.444 1.57 2.167 3.444 2.167 5.625 0 1.264-.254 2.43-.76 3.5a8.362 8.362 0 0 1-2.074 2.75h2v1.666h-5Z" />
    </g>
  </svg>
);

export const PublishedWithChangesIcon = (
  props: Omit<IconComponentProps, 'ref'>
) => <Icon component={PublishedWithChangesSvg} {...props} />;
