import cx from 'classnames';

import './GigantishLegendInitiative.less';
import { Skeleton } from 'antd';

interface Props {
  isSubInitiative?: boolean;
}

export const GigantishLegendInitiativeSkeleton = ({
  isSubInitiative = false,
}: Props) => {
  return (
    <div className="GigantishLegendInitiative">
      <div
        className={cx('GigantishLegendInitiative__row', {
          'GigantishLegendInitiative__row--small': isSubInitiative,
        })}
      >
        <Skeleton paragraph={false} className="mt" active />
        <div className="GigantishLegendInitiative__value"></div>
      </div>
    </div>
  );
};
