import { Alert, Tooltip, Card, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SprintDetails } from '../../../../../../sprint/summary/components/SprintDetails';
import { SprintProgress } from '../../../../../../sprint/summary/components/SprintProgress';
import { useWizardNavigation } from '../../../../../../../../components/WizardNavigationProvider';
import './PreviousSprintSummary.less';
import { MitemActionGroup } from './components/MitemActionGroup';
import {
  MitemAction,
  SprintMitemTable,
} from '../../../../../../sprint/summary/components/SprintMitemTable';
import {
  CheckCircleFilled,
  LockFilled,
  QuestionCircleOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { MitemActionsLegend } from './components/MitemActionsLegend';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { standardDateFormat } from '../../../../../../../../services/dateFormats';
import { MitemLayout } from './components/MitemLayout';
import { Btn } from '../../../../../../../../components/Button';
import {
  FinalizeMitemSprintDocument,
  GetCurrentSprintDocument,
  MitemActionType,
  MitemStatus,
  PreviousSprintSummaryQueryDocument,
  PreviousSprintSummary_MitemSprintFragment,
} from '../../../../../../../../generated/graphql';
import { howweErrorParser } from '../../../../../../../../services/howweErrorParser';
import { showNotification } from '../../../../../../../../services/fetchNotificationProperties';
import { PortalButton } from '../../MeetingNavigationBar';
import { TourLink } from '../../../../../../../../components/TourLink';

interface Props {
  teamId: string;
  previousSprint: PreviousSprintSummary_MitemSprintFragment;
}

export const PreviousSprintSummary = ({ teamId, previousSprint }: Props) => {
  const { t } = useTranslation();

  const { unlockStep, currentStepPath, goToNextStep } = useWizardNavigation();
  const [actionsNeeded, setActionsNeeded] = useState(false);
  const [actionTooltipVisible, setActionTooltipVisible] = useState(false);
  const finalized = previousSprint.finalized;
  const [finalizeSprintModalVisible, setFinalizeSprintModalVisible] =
    useState(false);

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (actionsNeeded === true) {
      timerId = setTimeout(() => {
        setActionsNeeded(false);
      }, 3000);
    }
    return () => timerId && clearTimeout(timerId);
  });

  const statusesQuery = useQuery(PreviousSprintSummaryQueryDocument, {
    variables: {
      teamId,
      startDate: previousSprint.startDate,
      endDate: previousSprint.endDate,
    },
    fetchPolicy: 'cache-and-network',
  });

  const skaStatuses =
    statusesQuery.data?.skaTimeStatuses.sprintKaStatuses ?? [];

  const groupedSkaStatuses = groupBasedOnActionRequired(skaStatuses);

  const [selectedMitemActions, setSelectedMitemActions] = useState<{
    [mitemId: string]: MitemAction | null;
  }>({});

  useEffect(() => {
    if (statusesQuery.loading === false)
      setSelectedMitemActions(
        groupedSkaStatuses.requiresAction.reduce(
          (acc, mitem) => ({
            ...acc,
            [mitem.id]: null,
          }),
          {}
        )
      );
    // if we follow the "rules" of hook dependencies below we will get an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusesQuery.loading]);

  const completedInTime = skaStatuses.filter((ss) => ss.status === 'COMPLETED');

  const [
    fetchCurrentSprint,
    { loading: fetchCurrentSprintLoading, error: fetchCurrentSprintError },
  ] = useLazyQuery(GetCurrentSprintDocument, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      unlockStep(currentStepPath);
      showNotification('success', {
        message: t('PreviousSprintSummary.finalizedToast'),
      });

      setFinalizeSprintModalVisible(false);
      goToNextStep();
    },
  });

  const [finalizeSprint, { loading, error }] = useMutation(
    FinalizeMitemSprintDocument,
    {
      onCompleted: () => {
        fetchCurrentSprint({
          variables: {
            teamId,
          },
        });
      },
    }
  );

  const onSubmit = () => {
    const mitemActions = Object.entries(selectedMitemActions).map(
      ([key, value]) => {
        if (value == null) {
          throw new Error(
            'No mitem action selected when trying to submit for key: ' + key
          );
        }
        if (value.action === MitemActionType.PLANNED) {
          return {
            mostImportantItemId: key,
            action: value.action,
            date: standardDateFormat(value.date),
          };
        }
        return {
          mostImportantItemId: key,
          action: value.action,
        };
      }
    );

    finalizeSprint({
      variables: { teamId, mitemActions: mitemActions },
    });
  };

  const numberOfUnhandledRestMitems = Object.entries(
    selectedMitemActions
  ).filter(([_, v]) => v == null).length;

  const LockIcon = previousSprint.locked ? LockFilled : UnlockOutlined;

  const mitemsToBeCompleted = Object.entries(selectedMitemActions).filter(
    ([_, v]) => v?.action === MitemActionType.COMPLETED
  );

  const numberOfCompletedMitems =
    completedInTime.length + (finalized ? 0 : mitemsToBeCompleted.length);

  const howweErrors = howweErrorParser(error || fetchCurrentSprintError);

  return (
    <div>
      <MitemLayout
        title={
          <div className="flx flx--ai-center">
            <div className="mr--l PreviousSprintSummary__lockIcon">
              <LockIcon />
            </div>

            <h2 className="mb--none mr--l">
              {t('PreviousSprintSummary.title')}
            </h2>
            <TourLink
              intercomTarget="previous sprint close sprint"
              engTourId={'3891'}
              sweTourId={'3894'}
            />

            <PortalButton
              actionButton={
                <Btn
                  type="callToAction"
                  loading={statusesQuery.loading}
                  id="finalizePreviousSprint"
                  disabled={finalized || statusesQuery.loading}
                  onClick={() => {
                    if (numberOfUnhandledRestMitems > 0) {
                      setActionsNeeded(true);
                      setActionTooltipVisible(true);
                    } else {
                      setFinalizeSprintModalVisible(true);
                    }
                  }}
                >
                  {t('PreviousSprintSummary.closeSprint')}
                </Btn>
              }
            />
          </div>
        }
        sidebar={
          <div className="mt--xl">
            <Card title={t('common.progress')} className="mb--s">
              <SprintProgress
                total={skaStatuses.length}
                completed={numberOfCompletedMitems}
              />
            </Card>

            <Card
              title={t('PreviousSprintSummary.keyActivityActionsTitle')}
              className="mb--s"
            >
              <MitemActionsLegend />
            </Card>

            <Card
              title={t('PreviousSprintSummary.sprintDetailsTitle')}
              className="mb--s"
            >
              <SprintDetails sprint={previousSprint} />
            </Card>
          </div>
        }
      >
        <div>
          <div className={'mb--xl ' + (actionsNeeded ? 'actionsNeeded' : '')}>
            <h3>{t('PreviousSprintSummary.requiresAction')}</h3>
            <SprintMitemTable
              timeStatuses={groupedSkaStatuses.requiresAction}
              includeStatus={false}
              pagination={false}
              mitemActions={selectedMitemActions}
              actionColumn={{
                title: (
                  <Tooltip
                    visible={actionTooltipVisible}
                    onVisibleChange={(visible) => {
                      setActionTooltipVisible(visible);
                    }}
                    title={t('PreviousSprintSummary.columnTooltip')}
                  >
                    {t('common.actions')}
                    <QuestionCircleOutlined className="space--l" />
                  </Tooltip>
                ),
                dataIndex: 'action',
                key: 'action',

                render: (_, record, index) => {
                  if (record.__typename === 'SkaTimeStatus')
                    return (
                      <MitemActionGroup
                        animationIndex={index}
                        teamId={previousSprint.teamId}
                        selectedAction={selectedMitemActions[record.id]}
                        onActionTaken={(updatedMitem) =>
                          setSelectedMitemActions({
                            ...selectedMitemActions,
                            ...{ [record.id]: updatedMitem },
                          })
                        }
                      />
                    );
                },
              }}
            />
          </div>
          <div>
            <h3>{t('PreviousSprintSummary.statusAtEndOfSprint')}</h3>
            <SprintMitemTable
              timeStatuses={groupedSkaStatuses.noActionRequired}
              pagination={false}
            />
          </div>
        </div>
      </MitemLayout>

      <Modal
        open={finalizeSprintModalVisible}
        onCancel={() => setFinalizeSprintModalVisible(false)}
        okText={t('PreviousSprintSummary.OkButtonText')}
        title={
          <div className="flx ">
            <div className="PreviousSprintSummary__ModalTitle mr">
              <CheckCircleFilled />
            </div>
            {t('PreviousSprintSummary.closeSprint')}
          </div>
        }
        onOk={onSubmit}
        confirmLoading={loading || fetchCurrentSprintLoading}
      >
        {t('PreviousSprintSummary.modalContent')}

        {howweErrors && (
          <Alert
            type="error"
            className="mt"
            message={t('PreviousSprintSummary.finalizeError')}
            description={
              <ul>
                {howweErrors.map((e, i) => (
                  <li key={i}>{e.translation}</li>
                ))}
              </ul>
            }
          />
        )}
      </Modal>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PREVIOUS_SPRINT_SUMMARY_SKA_TIME_STATUS = gql`
  fragment PreviousSprintSummary_SkaTimeStatus on SkaTimeStatus {
    id
    teamId
    tenantId
    name
    deadline
    status
    owner {
      id
      email
      displayName
      name
    }
    sprintKeyActivity {
      status
      id
    }
    ...SprintMitemTable_SkaTimeStatus
  }
  query previousSprintSummaryQuery(
    $teamId: ID!
    $startDate: String!
    $endDate: String!
  ) {
    skaTimeStatuses(teamId: $teamId, startDate: $startDate, endDate: $endDate) {
      sprintKaStatuses {
        ...PreviousSprintSummary_SkaTimeStatus
      }
    }
  }
`;

interface AlpineTimeStatus {
  sprintKeyActivity?: { status: MitemStatus } | null;
}

const actionRequired = (timeStatus: AlpineTimeStatus) => {
  if (timeStatus.sprintKeyActivity?.status === MitemStatus.COMPLETED)
    return false;
  if (timeStatus.sprintKeyActivity?.status === MitemStatus.ARCHIVED)
    return false;
  if (timeStatus.sprintKeyActivity?.status === MitemStatus.PLANNED)
    return false;

  return true;
};

const groupBasedOnActionRequired = <T extends AlpineTimeStatus>(
  skaStatuses: T[]
) => {
  const emptyGroupedStatuses = {
    requiresAction: [] as T[],
    noActionRequired: [] as T[],
  };

  const groupedSkaStatuses = skaStatuses.reduce((acc, ss) => {
    if (actionRequired(ss)) {
      acc.requiresAction.push(ss);
    } else {
      acc.noActionRequired.push(ss);
    }

    return acc;
  }, emptyGroupedStatuses);
  return groupedSkaStatuses;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PREVIOUS_PERIOD_SUMMARY_MITEM_SPRINT = gql`
  fragment PreviousSprintSummary_MitemSprint on MitemSprint {
    id
    startDate
    endDate
    finalized
    locked
    teamId
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FINALIZE_SPRINT = gql`
  mutation finalizeMitemSprint($teamId: ID!, $mitemActions: [MitemAction!]!) {
    finalizeMitemSprint(teamId: $teamId, mitemActions: $mitemActions) {
      endDate
      finalized
      teamId
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_CURRENT_SPRINT = gql`
  query getCurrentSprint($teamId: ID!) {
    mitemAccelerationMeetingSprints(teamId: $teamId) {
      currentSprint {
        ...AccelerationMeetingModal_MitemSprint
        mitems {
          id
          name
          archived
          archivedAt
          completed
          completedAt
          deadline
          definitionOfDone
          noMigAssociation
          milestone
          supportsInitiatives {
            id
            domainId {
              tenantId
              teamId
              itemId
            }
          }
          supportedInitiatives {
            id
            archived
            completed {
              value
            }
            tag {
              title
              iconId
              colorCode
            }
          }
          supportsInitiatives2 {
            id
            initiative {
              id
              tag {
                title
                iconId
                colorCode
              }
              metadata {
                completedAt
                archived
              }
            }
          }
          status
          tags {
            id
            name
            teamId
            active
            backgroundColor
            createdAt
          }
          owner {
            id
            email
            name
            displayName
            archivedAt
          }
          supportedMigs {
            id
            name
            domainId {
              itemId
              teamId
            }
          }
          teamId
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TERM_END_DATE = gql`
  query getTermEndDate($teamId: ID!) {
    currentMitemTerm(teamId: $teamId) {
      id
      endDate
    }
  }
`;
