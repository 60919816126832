import { Divider } from 'antd';
import { Btn } from '../../../../Button';

import { useTranslation } from 'react-i18next';

import Skeleton from 'react-loading-skeleton';

export const ReactivateInitiativeModalContentSkeleton = () => {
  const { t } = useTranslation();

  return (
    <div className="text-c flx flx--column">
      <h3>{t('ReactivateInitiativeModalContent.title')}</h3>
      <Skeleton />

      <Divider />
      <div>
        <Btn loading={true} disabled type="primary">
          {t('ReactivateInitiativeModalContent.reactivate')}
        </Btn>
      </div>
    </div>
  );
};
