import { Divider } from 'antd';

import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { MarkAsCompleteIcon } from '../../../../../../../../../components/initiatives/InitiativeActionsDropdown/icons/MarkAsCompleteIcon';
import { InitiativesColors } from '../../../../../../../../../styleVars';
import { Btn } from '../../../../../../../../../components/Button';

export const MarkMilestoneAsCompletedModalContentSkeleton = () => {
  const { t } = useTranslation();

  return (
    <div className="text-c flx flx--column">
      <h3>{t('MarkMilestoneAsCompletedModalContent.title')}</h3>
      <Skeleton width={300} />
      <div>
        <MarkAsCompleteIcon
          style={{ color: InitiativesColors.green, fontSize: 70 }}
        />
      </div>
      <Divider />
      <div>
        <Btn disabled type="success">
          {t('MarkMilestoneAsCompletedModalContent.markAsCompleted')}
        </Btn>
      </div>
    </div>
  );
};
