import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  YAxis,
} from 'recharts';
import { Colors } from '../../../../../../componentLibrary/Colors';
import { useTranslation } from 'react-i18next';
import { InitGraphLegendEntry } from './components/InitGraphLegendEntry';
import { DisplayDate } from '../../../../../../../components/DisplayDate';
import { InitiativeGraphSkeleton } from './InitiativeGraph skeleton';

const Y_AXIS_OFFSET = 24;

interface Props {
  startDate?: string | null;
  endDate?: string | null;
  periodData?: {
    date: string;
    completed?: number | null;
    total?: number | null;
  }[];

  legendGoal?: number | null;
  legendCompleted?: number | null;
  showLegends?: boolean;
  width?: number;
  height?: number;
}

export const InitiativeGraph = ({
  periodData,
  startDate,
  endDate,
  legendGoal,
  legendCompleted,
  height = 50,
}: Props) => {
  const { t } = useTranslation();
  if (periodData == null || periodData.length === 0) {
    // draw a placeholder empty graph
    periodData = [
      { date: '', total: 0 },
      { date: '', total: 0 },
    ];
  }

  startDate = startDate ?? periodData[0].date;
  endDate = endDate ?? periodData[periodData.length - 1].date ?? '--';

  return (
    <div style={{ marginLeft: -Y_AXIS_OFFSET }}>
      <ResponsiveContainer debounce={300} height={height} width="100%">
        <LineChart height={height} data={periodData}>
          <YAxis
            tickLine={false}
            width={20}
            fontSize={10}
            tickSize={2}
            tickCount={5}
            interval={0}
            type="number"
          />
          <CartesianGrid vertical={false} strokeDasharray="2 4" />
          <Line
            dataKey="total"
            stroke={Colors.Howwe.MAIN_ORANGE}
            strokeWidth={2}
            isAnimationActive={false}
            dot={false}
          />
          <Line
            dataKey="completed"
            stroke={Colors.Action.BLUE}
            strokeWidth={2}
            isAnimationActive={false}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
      <div
        className="flx flx--jc-space-between txt--secondary"
        style={{ marginLeft: Y_AXIS_OFFSET }}
      >
        <DisplayDate date={startDate} />
        <div className="flx flx--gap">
          <InitGraphLegendEntry
            label={t('common.completed')}
            value={legendCompleted}
            type="completed"
          />
          <InitGraphLegendEntry
            label={t('common.total')}
            value={legendGoal}
            type="goal"
          />
        </div>
        <DisplayDate date={endDate} />
      </div>
    </div>
  );
};

InitiativeGraph.Skeleton = InitiativeGraphSkeleton;
