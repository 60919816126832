import { useTranslation } from 'react-i18next';
import { Btn } from '../../../../../../../components/Button';
import { useState } from 'react';
import { Form, Modal } from 'antd';
import { gql, useMutation } from '@apollo/client';
import {
  CreateMilestoneButton__Initiative2Fragment,
  CreateMilestoneDocument,
  GetTenantBusinessPlanInitiativeDocument,
  SupportedEntityType,
} from '../../../../../../../generated/graphql';
import { showNotification } from '../../../../../../../services/fetchNotificationProperties';
import { howweErrorParser } from '../../../../../../../services/howweErrorParser';
import { MilestoneForm } from './components/MilestoneForm';
import { utcTimeFormat } from '../../../../../../../services/dateFormats';
import { PlusOutlined } from '@ant-design/icons';

interface Props {
  initiative: CreateMilestoneButton__Initiative2Fragment;
  disabled?: boolean;
}

export const CreateMilestoneButton = ({ initiative, disabled }: Props) => {
  const { t } = useTranslation();
  const [createMilestone, { loading }] = useMutation(CreateMilestoneDocument);
  const [showModal, setShowModal] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const { description, deadlineAt, name, assignedTo } = values;

      createMilestone({
        variables: {
          milestone: {
            description,
            deadlineAt: utcTimeFormat(deadlineAt),
            assignedTo: [
              {
                id: assignedTo,
              },
            ],
            supports: [
              {
                type: SupportedEntityType.initiative,
                item: {
                  domainId: {
                    itemId: initiative.domainId.itemId,
                    tenantId: initiative.domainId.tenantId,
                  },
                },
              },
            ],
            name: name,
          },
        },

        onCompleted: (data) => {
          showNotification('success', {
            message: (
              <strong>
                {t('CreateMilestoneButton.createSuccess', {
                  name: data.createMilestone.name,
                })}
              </strong>
            ),
          });
          setShowModal(false);
        },
        refetchQueries: () => [
          {
            query: GetTenantBusinessPlanInitiativeDocument,
            variables: { initiativeId: initiative.domainId.itemId },
          },
        ],
        onError: (e) => {
          const howweErrors = howweErrorParser(e);

          showNotification('error', {
            message: (
              <strong>
                <ul>
                  {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
                </ul>
              </strong>
            ),
          });
        },
      });
    });
  };

  return (
    <div>
      <Modal
        open={showModal}
        onCancel={() => {
          setShowModal(false);
          form.resetFields();
        }}
        onOk={handleSubmit}
        okButtonProps={{ loading }}
        destroyOnClose
      >
        <MilestoneForm
          form={form}
          tag={initiative?.tag}
          assignedToUserId={initiative?.assignedTo[0].id}
        />
      </Modal>

      <Btn
        onClick={() => setShowModal(true)}
        disabled={disabled}
        icon={<PlusOutlined />}
      >
        {t('CreateMilestoneButton.createMilestone')}
      </Btn>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CREATE_MILESTONE_BUTTON__INITIATIVE = gql`
  fragment CreateMilestoneButton__Initiative2 on Initiative2 {
    id
    domainId {
      itemId
      tenantId
    }
    assignedTo {
      id
      name
      email
      displayName
      initials
    }
    tag {
      ...MilestoneForm_InitiativeTag
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CREATE_MILESTONE_BUTTON__MUTATION = gql`
  mutation createMilestone($tenantId: ID, $milestone: MilestoneInput!) {
    createMilestone(tenantId: $tenantId, milestone: $milestone) {
      id
      name
      description
      rev
      domainId {
        itemId
      }
    }
  }
`;
