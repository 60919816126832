import './TeamGantishLegendRow.less';
import Skeleton from 'react-loading-skeleton';

export const TeamGantishLegendRowSkeleton = () => {
  return (
    <div className="TeamGantishLegendRow">
      <div className="TeamGantishLegendRow">
        <Skeleton style={{ width: '150px', fontSize: 11 }} />
      </div>
    </div>
  );
};
