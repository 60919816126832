import {
  PureQueryOptions,
  RefetchQueriesFunction,
  gql,
  useMutation,
} from '@apollo/client';
import { Alert, Modal, Select, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddPlannedKeyActivityToSprintModal_MitemFragment,
  UpdateMitemDeadlineDocument,
} from '../../../../../../../../../generated/graphql';
import {
  friendlyDate,
  standardDateFormat,
} from '../../../../../../../../../services/dateFormats';
import { momentToDayjs } from '../../../../../../../../../services/dateHelpers';
import { showNotification } from '../../../../../../../../../services/fetchNotificationProperties';
import { useAccelerationMeeting } from '../../../../AccelerationMeetingProvider';
import { PlannedKeyActivityCard } from './PlannedKeyActivities';

interface Props {
  mitem: AddPlannedKeyActivityToSprintModal_MitemFragment;
  showModal: boolean;
  isMember: (userId: string) => boolean | null;
  refetchQueries?: Array<string | PureQueryOptions> | RefetchQueriesFunction;
  currentSprintId: string | null;
  selectableDeadlines: {
    timePeriodStartDate: string;
    timePeriodEndDate: string;
  }[];
  onCompleted: () => void;
  onCancel: () => void;
}

export const AddPlannedKeyActivityToSprintModal = ({
  mitem,
  showModal,
  currentSprintId,
  isMember,
  refetchQueries,
  selectableDeadlines,
  onCancel,
  onCompleted,
}: Props) => {
  const { t } = useTranslation();
  const { sprintKeyActivityContext } = useAccelerationMeeting();
  const { Option } = Select;
  const [selectedDate, setSelectedDate] = useState(
    standardDateFormat(selectableDeadlines[0].timePeriodEndDate)
  );

  const [mutateDeadline] = useMutation(UpdateMitemDeadlineDocument, {
    refetchQueries,
    onError: () =>
      showNotification('success', {
        message: t('AddPlannedKeyActivityToSprintModal.error'),
      }),
    onCompleted: (data) => {
      sprintKeyActivityContext.addSprintKeyActivity(
        data.updateMitemDeadline.id,
        mitem.owner.id
      );
      onCompleted();
    },
  });

  // TODO: Should be changed, need to handle intercom on a higher level
  const isAccMeeting = window.location.href.includes('acceleration-meeting');

  useEffect(() => {
    if (!isAccMeeting) {
      (window as any).Intercom?.('update', {
        hide_default_launcher: showModal,
      });

      // sometimes the drawer is unmounted rather than getting the prop showModal={false}
      // this will make sure we show the intercom widget again in that case
      return () =>
        (window as any).Intercom?.('update', {
          hide_default_launcher: false,
        });
    }
  }, [showModal, isAccMeeting]);

  const commitKeyActivityToSprint = () => {
    mutateDeadline({
      variables: {
        teamId: mitem.teamId,
        mitemId: mitem.id,
        deadline: {
          dueDate: selectedDate,
          mostImportantItemSprintId: currentSprintId,
        },
      },
    });
  };

  return (
    <Modal
      title={t('AddPlannedKeyActivityToSprintModal.title')}
      open={showModal}
      onOk={commitKeyActivityToSprint}
      onCancel={onCancel}
      okText={t('AddPlannedKeyActivityToSprintModal.addButton')}
      width={420}
      destroyOnClose
    >
      {mitem.completed && (
        <Alert
          className="mb"
          message={t('AddPlannedKeyActivityToSprintModal.isCompleted')}
        />
      )}
      <div>
        <div className="mb--l">
          <Typography.Text>
            {t('AddPlannedKeyActivityToSprintModal.info')}
          </Typography.Text>
        </div>
        <PlannedKeyActivityCard mitem={mitem} isMember={isMember} fullSize />
        <Typography.Text>
          {t('AddPlannedKeyActivityToSprintModal.selectDeadline')}
        </Typography.Text>
        <Typography.Text className="space--l" type="secondary">
          {t('AddPlannedKeyActivityToSprintModal.inCurrentSprint')}
        </Typography.Text>
        <Select
          defaultValue={selectedDate}
          onChange={(value) => setSelectedDate(value)}
          className="fullWidth mb"
        >
          {selectableDeadlines?.map((p) => {
            const dateInApiFormat = standardDateFormat(p.timePeriodEndDate);
            const dateFriendlyFormat = friendlyDate(
              momentToDayjs(p.timePeriodEndDate)
            );
            return (
              <Option key={dateInApiFormat} value={dateInApiFormat}>
                {dateFriendlyFormat}
              </Option>
            );
          })}
        </Select>
      </div>
    </Modal>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ADD_PLANNED_KEY_ACTIVITY_TO_SPRINT_MODEL_MITEM = gql`
  fragment AddPlannedKeyActivityToSprintModal_Mitem on Mitem {
    id
    teamId
    completed
    ...PlannedKeyActivityCard_Mitem
  }
`;
