import './InitGraphLegendEntry.less';

interface Props {
  label: string;
  value?: number | null;
  type: 'goal' | 'completed';
  className?: string;
}

export const InitGraphLegendEntry = ({
  label,
  value,
  type,
  className,
}: Props) => {
  return (
    <div className={'flx flx--ai-center ' + (className ?? '')}>
      <span
        className={
          type === 'goal'
            ? 'InitGraphLegendEntry__goalDot'
            : 'InitGraphLegendEntry__completedDot'
        }
      />
      <span>{label}:</span>
      <span className="space--l bold">{value} </span>
    </div>
  );
};
