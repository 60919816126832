import { howweErrorParser } from '../services/howweErrorParser';
import { FallbackProps } from 'react-error-boundary';
import { Btn } from './Button';
import { Result, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

export const ErrorPage = ({ error, resetErrorBoundary }: FallbackProps) => {
  const howweErrors = howweErrorParser(error);
  const { t } = useTranslation();
  return (
    <Result status={500}>
      <div className="center-content">
        <h2>{t('ErrorPage.title')}</h2>
        <p>{t('ErrorPage.subTitle')}</p>
        <div className="card mb--xl pa--l flx flx--column">
          <Typography.Text strong className="mb">
            {t('ErrorPage.details')}
          </Typography.Text>
          <ul>
            {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
          </ul>
        </div>
        <Btn className="" onClick={resetErrorBoundary}>
          {t('ErrorPage.tryAgain')}
        </Btn>
      </div>
    </Result>
  );
};
