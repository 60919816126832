import { ReactNode, useEffect, useRef, useState } from 'react';
import { Btn } from './Button';
import './ClampText.less';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
  children: ReactNode;
  maxLines: number;
}

export default function ClampText({ children, maxLines }: Props) {
  const { t } = useTranslation();
  const contentRef = useRef<HTMLDivElement>(null);
  const [isClamped, setClamped] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    // Function that should be called on window resize
    function handleResize() {
      if (contentRef && contentRef.current) {
        setClamped(
          contentRef.current.scrollHeight > contentRef.current.clientHeight
        );
      }
    }

    handleResize(); // make sure it's called even if we don't resize..

    // Add event listener to window resize
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []); // Only run on mount

  return (
    <div style={{ ['--clamp-text-max-lines' as any]: maxLines }}>
      <div className="ClampText__content" ref={contentRef}>
        {children}
      </div>
      {isClamped && (
        <div>
          <Btn
            type="link"
            className="pl--none"
            onClick={() => {
              setModalOpen(true);
            }}
          >
            {t('ClampText.readAll')}
          </Btn>
        </div>
      )}
      <Modal
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        footer={null}
      >
        <div className="pt--l">{children}</div>
      </Modal>
    </div>
  );
}
