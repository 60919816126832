import './GantishLegend.less';
import { MilestoneStats } from './GantishLegend/MilestoneStats';
import { LegendSubInitiative } from './GantishLegend/LegendSubInitiative';
import { useTranslation } from 'react-i18next';

export const GantishLegendSkeleton = () => {
  const { t } = useTranslation();

  return (
    <div className="GantishLegend">
      <div className="GantishLegend__row">
        <h5 className="txt--secondary">{t('GantishLegend.title')}</h5>
        <MilestoneStats.Skeleton.Header />
      </div>

      <div className="GantishLegend__row">
        <h4>{t('GantishLegend.thisInitiative')}</h4>
        <MilestoneStats.Skeleton />
      </div>

      <div className="GantishLegend__row GantishLegend__row--spacer">
        <h4 className="mb">{t('GantishLegend.connectedInitiatives')}</h4>
      </div>

      <LegendSubInitiative.Skeleton />
    </div>
  );
};
