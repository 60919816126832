import { Divider } from 'antd';
import { Btn } from '../../../../../../../../../components/Button';
import { showNotification } from '../../../../../../../../../services/fetchNotificationProperties';
import { gql, useMutation, useSuspenseQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { howweErrorParser } from '../../../../../../../../../services/howweErrorParser';
import { ReactivateMilestoneModalContentSkeleton } from './ReactivateMilestoneModalContent skeleton';
import {
  ReactivateMilestoneModalContent_MutationDocument,
  ReactivateMilestoneModalContent_QueryDocument,
} from '../../../../../../../../../generated/graphql';

interface Props {
  milestoneId: string;
  onClose: () => void;
}

export const ReactivateMilestoneModalContent = ({
  milestoneId,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const { data } = useSuspenseQuery(
    ReactivateMilestoneModalContent_QueryDocument,
    { variables: { milestoneId: milestoneId }, fetchPolicy: 'network-only' }
  );

  const [reactivate, { loading }] = useMutation(
    ReactivateMilestoneModalContent_MutationDocument,
    {
      onError: (error) => {
        const howweErrors = howweErrorParser(error);

        showNotification('error', {
          message: t('ReactivateMilestoneModalContent.saveError'),
          description: (
            <strong>
              <ul>
                {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
              </ul>
            </strong>
          ),
        });
      },
      onCompleted: () => {
        showNotification('success', {
          message: t('ReactivateMilestoneModalContent.saveSuccess'),
        });
        onClose();
      },
    }
  );

  const handleReactivate = () => {
    reactivate({
      variables: {
        milestoneId: milestoneId,
        milestone: {
          completed: false,
          rev: data.milestone.rev,
        },
      },
    });
  };

  return (
    <div className="text-c flx flx--column">
      <h3>{t('ReactivateMilestoneModalContent.title')}</h3>
      {data.milestone.name}

      <Divider />
      <div>
        <Btn loading={loading} onClick={handleReactivate} type="primary">
          {t('ReactivateMilestoneModalContent.reactivate')}
        </Btn>
      </div>
    </div>
  );
};

ReactivateMilestoneModalContent.Skeleton =
  ReactivateMilestoneModalContentSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EDIT_MILESTONE = gql`
  mutation reactivateMilestoneModalContent_mutation(
    $milestoneId: ID!
    $milestone: MilestoneUpdateInput!
  ) {
    updateMilestone(milestoneId: $milestoneId, milestone: $milestone) {
      id
      name
      rev
      metadata {
        completedAt
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_MILESTONE = gql`
  query reactivateMilestoneModalContent_query($milestoneId: ID!) {
    milestone(milestoneId: $milestoneId) {
      id
      name
      rev
      metadata {
        completedAt
      }
    }
  }
`;
