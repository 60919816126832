import { gql, useSuspenseQuery } from '@apollo/client';
import { FormInstance } from 'antd';
import { GetTenantBusinessPlanInitiativeForEditDocument } from '../../../../../generated/graphql';
import { InitiativeForm } from '../../../InitiativeForm/InitiativeForm';

interface Props {
  form: FormInstance;
  initiativeId: string;
}

export const EditInitiativeModalContent = ({ form, initiativeId }: Props) => {
  const { data } = useSuspenseQuery(
    GetTenantBusinessPlanInitiativeForEditDocument,
    { variables: { initiativeId: initiativeId }, fetchPolicy: 'no-cache' }
  );

  const parentInitiativeTag = data.tenantInitiative.metadata.supports.find(
    (s) => s.__typename === 'SupportedInitiative'
  )?.item.tag;

  return (
    <InitiativeForm
      form={form}
      initialValue={data.tenantInitiative}
      parentInitiativeTag={parentInitiativeTag}
    />
  );
};

EditInitiativeModalContent.Skeleton = InitiativeForm.Skeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_EDIT_INITIATIVE_CONTENT = gql`
  query getTenantBusinessPlanInitiativeForEdit(
    $tenantId: ID
    $initiativeId: ID!
  ) {
    tenantInitiative(tenantId: $tenantId, initiativeId: $initiativeId) {
      id
      metadata {
        supports {
          ... on SupportedInitiative {
            type
            item {
              id
              tag {
                title
                iconId
                colorCode
              }
            }
          }
        }
      }
      ...InitiativeForm_Initiative2
    }
  }
`;
