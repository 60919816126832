import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  InitiativeHeaderSection__InitiativeDetailedReportResponseFragment,
  InitiativeHeaderSection_FinancialsMutationDocument,
} from '../../../../../generated/graphql';
import { DisplayName } from '../../../../../components/DisplayName';
import { InitiativeStatus } from '../../../../../components/initiative/InitiativeStatus';
import { InitiativeGraph } from './components/InitiativeGraph/InitiativeGraph';
import { InitiativeHeaderSectionSkeleton } from './InitiativeHeaderSection.skeleton';
import './InitiativeHeaderSection.less';
import { InitiativeValue } from '../../../strategicPlan/components/Gigantish/components/GigantishLegendInitiative/components/InitiativeValue';
import ClampText from '../../../../../components/ClampText';
import { Btn } from '../../../../../components/Button';
import { useState } from 'react';
import {
  financialPotentialSchema,
  UpdateFinancialPotentialForm,
} from './components/UpdateFinancialPotentialForm';
import { Form, Modal } from 'antd';
import { atLeastOne } from '../../../../../services/error-parser/helpers';
import {
  MilestoneOrActiveToggle,
  MilestoneOrActiveToggleEnum,
} from '../milestoneOrActivityToggle/MilestoneOrActiveToggle';

type Props = {
  initiativeReport: InitiativeHeaderSection__InitiativeDetailedReportResponseFragment;
};

export const InitiativeHeaderSection = ({ initiativeReport }: Props) => {
  const { t } = useTranslation();
  const { initiative } = initiativeReport || {};
  const [isFinancialPotentialModalOpen, setIsFinancialPotentialModalOpen] =
    useState(false);
  const [milestonesOrActivities, setMilestonesOrActivities] =
    useState<MilestoneOrActiveToggleEnum>(
      MilestoneOrActiveToggleEnum.Milestones
    );
  const graphData =
    milestonesOrActivities === MilestoneOrActiveToggleEnum.Milestones
      ? initiativeReport.accumulatedStats.milestones
      : initiativeReport.accumulatedStats.activities;

  const [financialPotentialForm] = Form.useForm();

  const [updateInitiativeFinancials] = useMutation(
    InitiativeHeaderSection_FinancialsMutationDocument
  );

  const handleUpdateFinancialPotential = async () => {
    const unsafeValues = await financialPotentialForm.validateFields();
    const values = financialPotentialSchema.parse(unsafeValues);

    await updateInitiativeFinancials({
      variables: {
        initiativeId: initiative.domainId.itemId,
        initiative: values,
      },
    });
    setIsFinancialPotentialModalOpen(false);
  };
  const supportedEntities = initiative.metadata.supports;

  const supportedMigs = supportedEntities.filter(
    (supported) => supported.__typename === 'SupportedMig'
  );

  const supportedInitiatives = supportedEntities.filter(
    (supported) => supported.__typename === 'SupportedInitiative'
  );

  return (
    <div className="InitiativeHeaderSection">
      <div className="flx--1 pr--xxl mr--xl pa--xl">
        <div className="mb--l">
          <h5 className="txt--secondary">
            {t('InitiativeHeaderSection.statusLabel')}
          </h5>
          <h3>
            <InitiativeStatus
              status={initiative.metadata.status}
              archived={initiative.metadata.archived}
            />
          </h3>
        </div>
        <h5 className="txt--secondary mb">
          {t('InitiativeHeaderSection.purposeAndObjectives')}
        </h5>
        <ClampText maxLines={8} key={initiative.id}>
          <div className="preserveLinebreaks">{initiative.description}</div>
          <ul className="mt">
            {initiative?.objectives.map((ob, index) => (
              <li key={index} className="mb--skipLast">
                {ob.text}
              </li>
            ))}
          </ul>
        </ClampText>
      </div>
      <div className="InitiativeHeaderSection__rightSection pa--xl">
        <div className="mb--xl">
          <h5 className="flx txt--secondary mb">
            <span className="mr--auto">
              {t('InitiativeHeaderSection.progress')}
            </span>
            <MilestoneOrActiveToggle
              radioClassName="font-size--sm"
              value={milestonesOrActivities}
              onChange={setMilestonesOrActivities}
            />
          </h5>
          <InitiativeGraph
            height={100}
            periodData={graphData.graph}
            legendCompleted={graphData.completed}
            legendGoal={graphData.total}
            startDate={initiative.startAt}
            endDate={initiative.endAt}
          />
        </div>

        <div className="InitiativeHeaderSection__meta">
          <div>
            <h5 className="txt--secondary mb--xs">
              {t('InitiativeHeaderSection.accountable')}
            </h5>
            {initiative?.assignedTo.map((assignee) => (
              <div key={assignee.id}>
                <DisplayName user={assignee} />
              </div>
            ))}
          </div>
          <div>
            <h5 className="txt--secondary mb--xs flx flx--ai-center">
              {t('InitiativeHeaderSection.financialValueLabel')}
              <Btn
                type="link"
                size="small"
                className="ml--auto"
                onClick={() => setIsFinancialPotentialModalOpen(true)}
              >
                <span className="font-size--sm bold">Updates</span>
              </Btn>
            </h5>
            <div className="flx flx--jc-space-between">
              {t('common.profit')}
              <InitiativeValue value={initiative.profit} />
            </div>
          </div>
          {atLeastOne(supportedMigs) ? (
            <div>
              <h5 className="txt--secondary mb--xs">
                {t('InitiativeHeaderSection.supportsMig')}
              </h5>
              {supportedMigs.map((supported) => (
                <div key={supported.item.id}>{supported.item.name}</div>
              ))}
              {supportedMigs.length === 0 && <div>--</div>}
            </div>
          ) : (
            <div>
              <h5 className="txt--secondary mb--xs">
                {t('InitiativeHeaderSection.supportsInitiative')}
              </h5>
              {supportedInitiatives.map((supported) => (
                <div key={supported.item.id}>{supported.item.name}</div>
              ))}
              {supportedInitiatives.length === 0 && <div>--</div>}
            </div>
          )}
          <div>
            <h5 className="txt--secondary mb--xs flx flx--ai-center">
              {t('InitiativeHeaderSection.financialValueLabel')}
              <Btn type="link" size="small" className="ml--auto">
                <span className="font-size--sm bold">Updates</span>
              </Btn>
            </h5>
            <div className="flx flx--jc-space-between">
              {t('common.revenue')}
              <InitiativeValue value={initiative.revenue} />
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={isFinancialPotentialModalOpen}
        onCancel={() => setIsFinancialPotentialModalOpen(false)}
        title={t('InitiativeHeaderSection.updatesModalTitle')}
        onOk={handleUpdateFinancialPotential}
      >
        <UpdateFinancialPotentialForm
          initiative={initiative}
          form={financialPotentialForm}
        />
      </Modal>
    </div>
  );
};

InitiativeHeaderSection.Skeleton = InitiativeHeaderSectionSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_HEADER_SECTION__INITIATIVE_DETAILED_REPORT_RESPONSE = gql`
  fragment InitiativeHeaderSection__InitiativeDetailedReportResponse on InitiativeDetailedReportResponse {
    id
    domainId {
      itemId
      tenantId
    }
    accumulatedStats {
      subInitiatives {
        total
      }

      milestones {
        completed
        total
        graph {
          date
          completed
          total
        }
      }
      activities {
        completed
        total
        graph {
          date
          completed
          total
        }
      }
    }
    companyInitiative
    initiative {
      domainId {
        itemId
        tenantId
      }
      name
      description
      assignedTo {
        id
        name
        email
        displayName
        initials
      }
      startAt
      endAt
      id
      objectives {
        text
        completed
      }
      tag {
        title
        iconId
        colorCode
      }
      metadata {
        status
        completedAt
        archived

        supports {
          ... on SupportedMig {
            type
            item {
              id
              name
            }
          }
          ... on SupportedInitiative {
            type
            item {
              id
              name
            }
          }
        }
      }
      profit {
        potential
        current
      }
      revenue {
        potential
        current
      }
      ...UpdateFinancialPotentialForm_Initiative
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UPDATE_FINANCIAL_POTENTIAL_MODALCONTENT_MUTATION = gql`
  mutation InitiativeHeaderSection_financialsMutation(
    $initiativeId: ID!
    $initiative: UpdateInitiative2Input!
  ) {
    updateTenantInitiative(
      initiativeId: $initiativeId
      initiative: $initiative
    ) {
      ...UpdateFinancialPotentialForm_Initiative
    }
  }
`;
