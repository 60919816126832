import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type ManageTeamsModalState = {
  milestoneId: string;
  milestoneName: string;
  type: 'manageTeams';
};

type CreateSkaModalState = {
  milestoneId: string;
  milestoneDomainId: { itemId: string };
  allowedTeams: { id: string; name: string }[];
  type: 'addKeyActivity';
};

export type MilestoneModalState =
  | {
      milestoneId: string;
      type: 'details' | 'edit' | 'archive' | 'markAsCompleted' | 'reactivate';
    }
  | ManageTeamsModalState
  | CreateSkaModalState;

export const useMilestoneModal = () => {
  const location = useLocation();
  const history = useHistory();
  const [activeModal, setActiveModal] = useState<MilestoneModalState | null>(
    null
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const milestoneDetailsId = params.get('milestoneDetails');

    if (milestoneDetailsId) {
      setActiveModal({ milestoneId: milestoneDetailsId, type: 'details' });
    }
  }, [location.search]);

  const closeModal = () => {
    const params = new URLSearchParams(location.search);
    params.delete('milestoneDetails');
    history.replace(`${location.pathname}?${params.toString()}`);
    setActiveModal(null);
  };

  const openModal = (modalState: MilestoneModalState) => {
    if (modalState.type === 'details') {
      const params = new URLSearchParams(location.search);
      params.set('milestoneDetails', modalState.milestoneId);
      history.replace(`${location.pathname}?${params.toString()}`);
    } else {
      setActiveModal(modalState);
    }
  };

  return {
    activeModal,
    openModal,
    closeModal,
  };
};
