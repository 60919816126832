import dayjs from 'dayjs';
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';

import { Btn } from '../../../Button';
import './GantishChart.less';
import { ChartSubinitiative } from './GantishChart/ChartSubinitiative';
import { MonthCell } from './GantishChart/MonthCell';

const today = dayjs();

export const GantishChartSkeleton = () => {
  const startDate = dayjs().subtract(3, 'month').startOf('month');

  const months = Array.from({ length: 6 }, (_, i) => startDate.add(i, 'month'));

  const monthProgress = (today.date() / today.daysInMonth()) * 100;

  return (
    <div
      className="GantishChart"
      style={{ ['--month-percent' as any]: monthProgress + '%' }}
    >
      <div className="GantishChart__row GantishChart__row--header">
        <Btn
          className="GantishChart__backBtn"
          icon={<CaretLeftFilled style={{ fontSize: ICON_SIZE }} />}
          type="link"
          disabled
        />

        {months.map((m, i) => (
          <MonthCell key={i} startOfMonth={m}>
            <span className="ml">
              {m.format(isJanuary(m) ? 'MMM YYYY' : 'MMM')}
            </span>
          </MonthCell>
        ))}

        <Btn
          className="GantishChart__forwardBtn"
          icon={<CaretRightFilled style={{ fontSize: ICON_SIZE }} />}
          type="link"
          disabled
        />
      </div>

      <div className="GantishChart__row">
        {months.map((m, i) => (
          <MonthCell key={i} startOfMonth={m}></MonthCell>
        ))}
      </div>

      <div className="GantishChart__row GantishChart__row--spacer">
        {months.map((m, i) => (
          <MonthCell key={i} startOfMonth={m} />
        ))}
      </div>

      <div id="subInitiatives">
        <ChartSubinitiative.Skeleton months={months} />
        <ChartSubinitiative.Skeleton months={months} />
      </div>
    </div>
  );
};

const isJanuary = (date: dayjs.Dayjs) => date.month() === 0;

const ICON_SIZE = 11;
