import React, { Suspense } from 'react';
import { Modal } from 'antd';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage } from '../../../../ErrorPage';
import { ReactivateInitiativeModalContent } from './ReactivateInitiativeModalContent';

interface ReactivateInitiativeModalProps {
  initiativeId: string;
  visible: boolean;
  onClose: () => void;
}

export const ReactivateInitiativeModal: React.FC<
  ReactivateInitiativeModalProps
> = ({ initiativeId, visible, onClose }) => {
  return (
    <Modal open={visible} footer={null} onCancel={onClose}>
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <Suspense fallback={<ReactivateInitiativeModalContent.Skeleton />}>
          <ReactivateInitiativeModalContent
            initiativeId={initiativeId}
            onClose={onClose}
          />
        </Suspense>
      </ErrorBoundary>
    </Modal>
  );
};
