import { useMemo, useState } from 'react';
import { usePermissionForTeams } from '../../../../../../../hooks/usePermissionForTeams';
import {
  Action,
  TeamResourceType,
} from '../../../../../../../generated/graphql';
import { useTranslation } from 'react-i18next';

import { Btn } from '../../../../../../../components/Button';
import { CreateSkaForTenantInitiativeDrawer } from './CreateSkaForTenantInitiativeDrawer';
import { Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

interface Props {
  contributingTeams: { id: string; name: string }[];
  initiativeId: string;
}

export const CreateSkaForTenantInitiativeButton = ({
  contributingTeams,
  initiativeId,
}: Props) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const { t } = useTranslation();

  const { data } = usePermissionForTeams(
    TeamResourceType.SPRINT_KA,
    Action.CREATE
  );

  const allowedTeams = useMemo(() => {
    return (
      contributingTeams.filter((ct) =>
        data?.permittedToPerformActionForTeam.permittedResources.some(
          (prt) => ct.id === prt.teamId
        )
      ) ?? []
    );
  }, [
    data?.permittedToPerformActionForTeam.permittedResources,
    contributingTeams,
  ]);

  const isAllowedToCreate = allowedTeams.length > 0;

  return (
    <div>
      {isAllowedToCreate && (
        <Tooltip
          placement="top"
          title={t('common.create')}
          mouseEnterDelay={0.7}
        >
          <Btn
            icon={<PlusOutlined />}
            onClick={() => {
              setShowDrawer(true);
            }}
          >
            {t('CreateSkaForTenantInitiativeButton.createKeyActivity')}
          </Btn>
        </Tooltip>
      )}
      <CreateSkaForTenantInitiativeDrawer
        contributingTeams={allowedTeams}
        initiativeId={initiativeId}
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
      />
    </div>
  );
};
