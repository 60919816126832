import { gql, useSuspenseQuery } from '@apollo/client';
import { GetTopInitiativesForNavigationDocument } from '../../../../generated/graphql';
import { InitiativeTreeWrapper } from './initiativeTreeWrapper/InitiativeTreeWrapper';
import { useTranslation } from 'react-i18next';
import { InitiativesNavigationSkeleton } from './InitiativesNavigation.skeleton';

export const InitiativesNavigation = () => {
  const { t } = useTranslation();
  const { data } = useSuspenseQuery(GetTopInitiativesForNavigationDocument, {
    fetchPolicy: 'no-cache', // TODO: Remove this when we have a proper data from BE
  });

  return (
    <div>
      <div className="mb">
        <h3 className="mb--xs">
          {t('InitiativesNavigation.companyInitiatives')}
        </h3>
        <InitiativeTreeWrapper
          initiatives={data.topInitiativeReport.initiatives.filter(
            (i) => i.companyInitiative
          )}
        />
      </div>
      <div>
        <h3 className="mb--xs">
          {t('InitiativesNavigation.otherInitiatives')}
        </h3>
        <InitiativeTreeWrapper
          initiatives={data.topInitiativeReport.initiatives.filter(
            (i) => !i.companyInitiative
          )}
        />
      </div>
    </div>
  );
};

InitiativesNavigation.skeleton = InitiativesNavigationSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_INITIATIVES_NAVIGATION = gql`
  query getTopInitiativesForNavigation(
    $tenantId: ID
    $filter: TopInitiativeFilter
  ) {
    topInitiativeReport(tenantId: $tenantId, filter: $filter) {
      initiatives {
        id
        companyInitiative
        ...initiativeTreeWrapper__TopInitiativeReport
      }
    }
  }
`;
