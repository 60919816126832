import './MilestoneActivitiesTable.less';
import {
  GetSprintKeyActivitiesForMilestoneDocument,
  MilestoneActivityTable__MitemFragment,
} from '../../../../../../../../generated/graphql';
import { MilestoneActivityTableRow } from './MilestoneActivityTableRow';
import { useTranslation } from 'react-i18next';
import { gql, useSuspenseQuery } from '@apollo/client';
import { stringSort } from '../../../../../../../../services/stringSort';
import { MilestoneActivitiesTableSkeleton } from './MilestoneActivitiesTable.skeleton';
import { Empty, Modal } from 'antd';
import { MilestoneActivitiesTableError } from './MilestoneActivitiesTable.Error2';
import { SprintKeyActivityDetails } from '../../../../../../initiatives_old/initiativesPageV1/initiativeContent/activityProgress/SprintKeyActivityDetails';
import { useState } from 'react';

interface Props {
  milestoneItemId: string;
  filterPredicate?: (params: MilestoneActivityTable__MitemFragment) => boolean;
}

export const MilestoneActivitiesTable = ({
  milestoneItemId,
  filterPredicate,
}: Props) => {
  const [selectedSkaId, setSelectedSkaId] = useState<string | null>(null);
  const { data } = useSuspenseQuery(
    GetSprintKeyActivitiesForMilestoneDocument,
    { variables: { milestoneId: milestoneItemId } }
  );
  const { t } = useTranslation();

  const sortedKa =
    data.sprintKeyActivitiesForMilestone.sprintKeyActivities.toSorted((a, b) =>
      stringSort(a.deadline, b.deadline)
    );

  const filteredActivities = filterPredicate
    ? sortedKa.filter(filterPredicate)
    : sortedKa;

  const selectedSka = sortedKa.find((ska) => ska.id === selectedSkaId);

  return (
    <>
      <Modal
        open={selectedSka != null}
        onCancel={() => setSelectedSkaId(null)}
        footer={null}
        modalRender={() =>
          selectedSka && (
            <SprintKeyActivityDetails
              sprintKeyActivity={selectedSka}
              onClose={() => setSelectedSkaId(null)}
            />
          )
        }
      />
      <div className="MilestoneActivitiesTable">
        <table className="MilestoneActivitiesTable">
          <thead>
            <tr>
              <th className="text-l txt--secondary">{t('common.deadline')}</th>
              <th className="text-l txt--secondary">{t('common.activity')}</th>
              <th className="text-l txt--secondary">{t('common.team')}</th>
              <th className="text-c txt--secondary">{t('common.status')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredActivities.map((ska) => (
              <MilestoneActivityTableRow
                key={ska.id}
                keyActivity={ska}
                onSelectedSka={setSelectedSkaId}
              />
            ))}
            {filteredActivities.length === 0 && (
              <tr className="text-c" style={{ height: 120 }}>
                <td colSpan={5}>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    className="mt--xxl"
                    description={t('MilestoneActivitiesTable.noData')}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

MilestoneActivitiesTable.Skeleton = MilestoneActivitiesTableSkeleton;
MilestoneActivitiesTable.Error = MilestoneActivitiesTableError;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_SPRINT_KEY_ACTIVITIES_FOR_MILESTONES = gql`
  query getSprintKeyActivitiesForMilestone($tenantId: ID, $milestoneId: ID!) {
    sprintKeyActivitiesForMilestone(
      tenantId: $tenantId
      milestoneId: $milestoneId
    ) {
      sprintKeyActivities {
        id
        ...MilestoneActivityTable__Mitem
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_ACTIVITY_TABLE__MITEM = gql`
  fragment MilestoneActivityTable__Mitem on Mitem {
    id
    teamId
    ...MilestoneActivityTableRow__Mitem
    ...SprintKeyActivityDetails_Mitem
  }
`;
