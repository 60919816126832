import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  MilestoneOrActiveToggle,
  MilestoneOrActiveToggleEnum,
} from '../milestoneOrActivityToggle/MilestoneOrActiveToggle';
import { Radio } from 'antd';
import { SubInitiativeSection } from './components/subInitiativeSection/SubInitiativeSection';
import { gql } from '@apollo/client';
import { InitiativeOverviewSection__InitiativeDetailedReportResponseFragment } from '../../../../../generated/graphql';
import { InitiativeDetailsTreeNode } from '../InitiativeDetails';
import { OverviewSectionSkeleton } from './OverviewSection.skeleton';
import { Gantish } from '../../../../../components/initiatives/Gantish/Gantish';

enum OverviewSectionEnum {
  CurrentProgress,
  SubInitiatives,
}

const options = [
  { label: 'Current Progress', value: OverviewSectionEnum.CurrentProgress },
  { label: 'Sub-initiatives', value: OverviewSectionEnum.SubInitiatives },
];

interface Props {
  report: InitiativeOverviewSection__InitiativeDetailedReportResponseFragment;
  initiativeReportNode: InitiativeDetailsTreeNode;
}

export const OverviewSection = ({ report, initiativeReportNode }: Props) => {
  const [overviewSectionSelection, setOverviewSectionSelection] = useState(
    OverviewSectionEnum.CurrentProgress
  );

  const { t } = useTranslation();
  const [milestonesOrActivities, setMilestonesOrActivities] =
    useState<MilestoneOrActiveToggleEnum>(
      MilestoneOrActiveToggleEnum.Milestones
    );

  return (
    <div>
      <h3 className="mb--xl">{t('OverviewSection.title')}</h3>
      <div className="mb flx flx--jc-space-between flx--ai-center">
        <MilestoneOrActiveToggle
          value={
            overviewSectionSelection === OverviewSectionEnum.CurrentProgress
              ? MilestoneOrActiveToggleEnum.Milestones
              : milestonesOrActivities
          }
          onChange={setMilestonesOrActivities}
          disabled={
            overviewSectionSelection === OverviewSectionEnum.CurrentProgress
          }
        />
        <Radio.Group
          options={options}
          onChange={({ target: { value } }) =>
            setOverviewSectionSelection(value)
          }
          value={overviewSectionSelection}
          optionType="button"
        />
      </div>
      {overviewSectionSelection === OverviewSectionEnum.CurrentProgress && (
        <div className="card mb--l">
          <Gantish initiativeReportNode={initiativeReportNode} />
        </div>
      )}

      {overviewSectionSelection === OverviewSectionEnum.SubInitiatives && (
        <SubInitiativeSection
          subInitiatives={report.subInitiatives}
          milestonesOrActivities={milestonesOrActivities}
        />
      )}
    </div>
  );
};

OverviewSection.Skeleton = OverviewSectionSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_OVERVIEW_SECTION__INITIATIVE_DETAILED_REPORT_RESPONSE = gql`
  fragment InitiativeOverviewSection__InitiativeDetailedReportResponse on InitiativeDetailedReportResponse {
    id
    domainId {
      itemId
      tenantId
    }
    subInitiatives {
      ...SubInitiativeSection__DirectChildInitiativeReport
    }
    ...Gantish__InitiativeDetailedReportResponse
  }
`;
