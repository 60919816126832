import { Modal } from 'antd';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage } from '../../../../ErrorPage';
import { MarkInitiativeAsCompletedModalContent } from './MarkInitiativeAsCompletedModalContent';

interface Props {
  visible: boolean;
  initiativeId: string;
  onClose?: () => void;
}

export const MarkInitiativeAsCompletedModal = ({
  initiativeId,
  visible,
  onClose,
}: Props) => {
  return (
    <Modal
      open={visible}
      footer={null}
      style={{ top: 20 }}
      onCancel={onClose}
      destroyOnClose
    >
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <Suspense fallback={<MarkInitiativeAsCompletedModalContent.Skeleton />}>
          <MarkInitiativeAsCompletedModalContent
            initiativeId={initiativeId}
            onClose={onClose}
          />
        </Suspense>
      </ErrorBoundary>
    </Modal>
  );
};
