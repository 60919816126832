import { Checkbox, Table, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { MitemFilterStatus } from '../../../../sprint/common/components/mitemFilters/StatusFilter';
import '../../../../sprint/summary/components/SprintMitemTable.less';
import { mitemStatusColor } from '../../../../../../styleVars';
import { recordDefaultSortOrder } from '../../../../../../services/sprintKeyActivityUtils';
import { DisplayName } from '../../../../../../components/DisplayName';
import { stringSort } from '../../../../../../services/stringSort';
import { friendlyUsername } from '../../../../../../services/friendlyUsername';
import { Moment } from 'moment';
import {
  MitemActionType,
  MitemDetails_MitemFragment,
  SkaTable_MitemFragment,
} from '../../../../../../generated/graphql';
import { MitemHistory } from '../../../../sprint/common/components/MitemHistory';
import { DisplayDate } from '../../../../../../components/DisplayDate';
import { ExpandArrow } from '../../../../../../components/ExpandArrow';
import {
  migFilterCompare,
  ownerFilterCompare,
  statusFilterCompare,
} from '../../../../sprint/common/hooks/useMitemFilters';
import { CommitColumn } from '../../../../sprint/summary/components/sprintMitemTable/CommitColumn';
import { dateCompare } from '../../../../../../services/dateHelpers';
import { TagImage } from '../../../../setup/overviewPage/labels/TagImage';
import { InitiativeTag } from '../../../../../../components/initiative/InitiativeTag';
import {
  Icons,
  InitiativeIcon,
} from '../../../../../company/initiatives_old/initiativesPageV1/InitiativeIcons';
import { StarIcon } from '../../../../../../icons/Star';
import { MinusOutlined } from '@ant-design/icons';
import { Colors } from '../../../../../componentLibrary/Colors';
import { gql } from '@apollo/client';
import { useAccelerationMeeting } from '../AccelerationMeetingProvider';
import { useMemo } from 'react';
import { StatusTag } from '../../../../../../components/StatusTag';
import { useTenantDetails } from '../../../../../../hooks/useTenantDetails';
import { match } from 'ts-pattern';

export type MitemRescheduleAction = {
  action: MitemActionType.PLANNED;
  date: Moment;
};

export type MitemCompleteAction = {
  action: MitemActionType.COMPLETED;
};

export type MitemDeleteAction = {
  action: MitemActionType.ARCHIVED;
};

export type MitemAction =
  | MitemRescheduleAction
  | MitemDeleteAction
  | MitemCompleteAction;

interface Props<T extends SkaTable_MitemFragment> {
  mitems: T[] | null;
  loading?: boolean;
  filters?: { ownerId?: string; status?: MitemFilterStatus; migId?: string };
  actionColumn?: ColumnProps<T> | null;
  pagination?: boolean;
  includeStatus?: boolean;
  committable?: boolean;
  mitemActions?: {
    [mitemId: string]: MitemAction | null;
  };
}

interface ExcludedColumnProps<T> extends ColumnProps<T> {
  excluded?: boolean;
}

export const SkaTable = <T extends SkaTable_MitemFragment>({
  mitems,
  loading,
  filters,
  actionColumn,
  pagination = false,
  includeStatus = true,
  committable = false,
  mitemActions,
}: Props<T>) => {
  const { features } = useTenantDetails();
  const { t } = useTranslation();

  const { sprintKeyActivityContext } = useAccelerationMeeting();
  const numberOfCommitted = useMemo(() => {
    return Object.values(sprintKeyActivityContext.commitments).filter(
      (c) =>
        c.commitment.committed && mitems?.some((m) => m.id === c.sprintKaId)
    ).length;
  }, [mitems, sprintKeyActivityContext.commitments]);

  const filteredMitems = mitems
    ?.filter((m) => ownerFilterCompare(m.owner.id, filters?.ownerId))
    .filter((m) => statusFilterCompare(m.status, filters?.status))
    .filter((m) =>
      migFilterCompare(
        m.supportedMigs.map((m) => m.domainId.itemId) ?? [],
        filters?.migId
      )
    );

  const sprintMitemColumns: ExcludedColumnProps<SkaTable_MitemFragment>[] = [
    {
      title: t('common.sprintKeyActivity.status'),
      excluded: !includeStatus,
      width: 90,
      sorter: (a, b) =>
        recordDefaultSortOrder.indexOf(a.status) -
        recordDefaultSortOrder.indexOf(b.status),
      render: (_, mitem) => {
        return (
          <div className="flx flx--column">
            <StatusTag status={mitem.status} />
          </div>
        );
      },
    },
    {
      title: t('common.title'),
      dataIndex: 'name',
      sorter: (a, b) => stringSort(a.name, b.name),
    },
    {
      title: t('common.sprintKeyActivity.owner'),
      dataIndex: ['owner', 'id'],
      sorter: (a, b, sortOrder) => {
        const ownerSort = stringSort(
          friendlyUsername(a.owner),
          friendlyUsername(b.owner)
        );
        const deadlineSort = dateCompare(a.deadline, b.deadline);
        const deadlineDirection = sortOrder === 'descend' ? -1 : 1;

        return ownerSort || deadlineSort * deadlineDirection;
      },
      render: (_, mitem) => {
        return (
          <div>
            <DisplayName user={mitem.owner} />
          </div>
        );
      },
    },
    {
      title: t('common.sprintKeyActivity.deadline'),
      sorter: (a, b) => {
        const deadlineSort = dateCompare(a.deadline, b.deadline);
        const ownerSort = stringSort(
          friendlyUsername(a.owner),
          friendlyUsername(b.owner)
        );
        return deadlineSort || ownerSort;
      },
      defaultSortOrder: 'ascend',
      dataIndex: 'deadline',
      render: (deadline, mitem) => {
        const formattedDeadline = <DisplayDate date={deadline} />;

        const handledMitem = mitemActions?.[mitem.id];
        if (handledMitem?.action === MitemActionType.PLANNED) {
          return (
            <div className="flx flx--column">
              <Typography.Text type="secondary" className="lineThrough">
                {formattedDeadline}
              </Typography.Text>
              <div style={{ color: mitemStatusColor.PLANNED }}>
                <DisplayDate date={handledMitem.date} />
              </div>
            </div>
          );
        }
        return formattedDeadline;
      },
    },
    {
      title: <StarIcon title={t('common.sprintKeyActivity.milestone')} />,
      width: 48,
      dataIndex: 'milestone',
      render(value, record) {
        return (
          <span style={{ fontSize: 16 }}>
            {record.milestone ? (
              <StarIcon />
            ) : (
              <MinusOutlined style={{ color: Colors.Grays.FRAMES_AND_LINES }} />
            )}
          </span>
        );
      },
    },
    {
      title: t('common.sprintKeyActivity.tags'),
      render: (mitem: SkaTable_MitemFragment) => {
        const teamTags = mitem.tags ?? [];

        const sortedTeamTags =
          [...teamTags]
            .sort((a, b) => {
              return stringSort(a.name, b.name);
            })
            .map((t) => <TagImage key={t.id} tag={t} />) ?? [];

        const supportedInitiatives = match(features)
          .with({ teamInitiativesEnabled: true }, () => {
            return (
              mitem.supportedInitiatives?.map((teamInitiative) => {
                return {
                  id: teamInitiative.id,
                  tag: teamInitiative.tag,
                  metadata: {
                    archived: teamInitiative.archived,
                    completedAt: teamInitiative.completed.value
                      ? teamInitiative.completed.setAt
                      : null,
                  },
                };
              }) ?? []
            );
          })
          .with({ tenantInitiativesEnabled: true }, () => {
            return mitem.supportsInitiatives2?.map((i) => i.initiative) ?? [];
          })
          .otherwise(() => {
            return [];
          });

        const sortedInitiativeTags =
          [...supportedInitiatives]
            .sort((a, b) => {
              return stringSort(a.tag.title, b.tag.title);
            })
            .map(({ id, tag, metadata }) => (
              <InitiativeTag
                key={id}
                title={tag.title}
                borderColor={tag.colorCode}
                icon={Icons[tag.iconId as InitiativeIcon]}
                completed={metadata.completedAt != null}
                archived={metadata.archived}
              />
            )) ?? [];
        return (
          <div className="flx flx--wrap flx--gap--xs dimmable">
            {sortedInitiativeTags}
            {sortedTeamTags}
          </div>
        );
      },
    },
    ...(actionColumn ? [actionColumn] : []),
    {
      title: (
        <CommitmentColumnHeader
          numberOfSka={mitems?.length ?? 0}
          numberOfCommitted={numberOfCommitted}
          onToggleAll={(checked: boolean) =>
            sprintKeyActivityContext.toggleAllCommitments(checked, mitems ?? [])
          }
        />
      ),
      defaultSortOrder: 'ascend',
      excluded: !committable,
      dataIndex: 'commitment',
      render: (_, mitem) => {
        return (
          committable && (
            <CommitColumn sprintKaId={mitem.id} ownerId={mitem.owner.id} />
          )
        );
      },
    },
    Table.EXPAND_COLUMN,
  ];

  return (
    <Table
      className="SprintMitemTableCard__mitemTable"
      columns={sprintMitemColumns.filter((c) => !c.excluded)}
      dataSource={filteredMitems ?? []}
      expandable={{
        expandRowByClick: true,
        expandedRowRender: (mitem) => <MitemDetails mitem={mitem} />,
        expandIcon: ({ expanded, onExpand, record }) => (
          <ExpandArrow
            onClick={(e) => onExpand(record, e)}
            expanded={expanded}
          />
        ),
      }}
      loading={loading}
      pagination={!pagination ? false : { size: 'small' }}
      rowKey={(mitem) => mitem.id}
    />
  );
};

interface MitemDetailsProps {
  mitem: MitemDetails_MitemFragment;
}

const CommitmentColumnHeader = ({
  numberOfSka,
  numberOfCommitted,
  onToggleAll,
}: {
  numberOfSka: number;
  numberOfCommitted: number;
  onToggleAll: (checked: boolean) => void;
}) => {
  const { t } = useTranslation();
  const allChecked = numberOfCommitted === numberOfSka;
  const someChecked = numberOfCommitted > 0;

  return (
    <span data-intercom-target="Toggle all committed">
      <Checkbox
        checked={allChecked}
        indeterminate={someChecked && !allChecked}
        onChange={(e) => {
          const checked = e.target.checked;
          onToggleAll(checked);
        }}
        className="mr"
      />
      {t('common.commitment_one')}
    </span>
  );
};

const MitemDetails = ({ mitem }: MitemDetailsProps) => {
  const { t } = useTranslation();
  return (
    <div className="flx">
      <div className="flx--1 SprintMitemTableCard__detailsSection">
        {mitem.supportedMigs.length > 0 &&
          mitem.supportedMigs.map((sm) => (
            <div className="flx flx--column mb" key={sm.id}>
              <Typography.Text>{t('SkaTable.AlignedToMig')}</Typography.Text>
              <Typography.Text type="secondary">{sm.name}</Typography.Text>
            </div>
          ))}
        {mitem.supportedMigs.length === 0 && (
          <div className="flx flx--column mb">
            <Typography.Text>{t('SkaTable.noMig')}</Typography.Text>
            <Typography.Text type="secondary">
              {mitem.noMigAssociation}
            </Typography.Text>
          </div>
        )}
        <div className="flx flx--column ">
          <Typography.Text>{t('common.definitionOfDone')}</Typography.Text>
          <Typography.Text type="secondary" style={{ whiteSpace: 'pre-wrap' }}>
            {mitem.definitionOfDone}
          </Typography.Text>
        </div>
      </div>
      <div className="flx--1 SprintMitemTableCard__detailsSection">
        <div>
          <Typography.Text>{t('common.history')}</Typography.Text>
          <div className="mt">
            <MitemHistory teamId={mitem.teamId} mitemId={mitem.id} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const SKA_TABLE_MITEM_FRAGMENT = gql`
  fragment SkaTable_Mitem on Mitem {
    id
    status
    name
    deadline
    milestone
    tags {
      id
      name
      backgroundColor
    }
    owner {
      id
      name
      email
      displayName
      archivedAt
    }
    supportedInitiatives {
      id
      tag {
        title
        iconId
        colorCode
      }
      archived
      completed {
        value
        setAt
      }
    }
    supportsInitiatives2 {
      id
      initiative {
        id
        tag {
          title
          iconId
          colorCode
        }
        tag {
          title
          iconId
          colorCode
        }
        metadata {
          completedAt
          archived
        }
      }
    }
    supportedMigs {
      id
      domainId {
        teamId
        itemId
      }
    }
    ...MitemDetails_Mitem
  }
`;

//eslint-disable-next-line
const MITEM_DETAILS_FRAGMENT = gql`
  fragment MitemDetails_Mitem on Mitem {
    id
    definitionOfDone
    noMigAssociation
    teamId
    supportedMigs {
      id
      name
      domainId {
        teamId
        itemId
      }
    }
  }
`;
