import { Modal, Spin, Table, Tooltip } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { gql, useSuspenseQuery } from '@apollo/client';
import {
  Action,
  GetSprintKeyActivitiesForInitiativeDocument,
  MitemStatus,
  SprintKeyActivityTable__MItemFragment,
  TeamResourceType,
} from '../../../../../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { friendlyDate } from '../../../../../../../services/dateFormats';
import { StatusTag } from '../../../../../../../components/StatusTag';
import { stringSort } from '../../../../../../../services/stringSort';
import { SprintKeyActivityTableSkeleton } from './SprintKeyActivityTable.Skeleton';
import { usePermissionForTeams } from '../../../../../../../hooks/usePermissionForTeams';
import { toMap } from '../../../../../../../services/toMap';
import { EditSprintKeyActivityDrawerWithButton } from '../../../../../initiatives_old/initiativesPageV1/initiativeContent/activityProgress/EditSkaDrawerWithButton';
import { Btn } from '../../../../../../../components/Button';
import { useState } from 'react';
import { ManageSearchIcon } from '../../../../../../../icons/ManageSearch';
import { SprintKeyActivityDetails } from '../../../../../initiatives_old/initiativesPageV1/initiativeContent/activityProgress/SprintKeyActivityDetails';

interface Props {
  initiativeId: string;
  filters: {
    status: {
      completed: boolean;
      overdue: boolean;
      planned: boolean;
    };
    title: string | null;
    initiativeId: string | null;
  };
}

const matchSprintKeyActivityByStatus = (
  ska: { status: MitemStatus },
  filterStatus: {
    completed: boolean;
    overdue: boolean;
    planned: boolean;
  }
) => {
  const activityStatus = ska.status.toLowerCase();
  if (filterStatus.planned && activityStatus === 'active') return true;
  return filterStatus[activityStatus as keyof typeof filterStatus];
};

const matchSprintKeyActivityByTitle = (
  ska: { name: string },
  filterTitle: string | null
) => {
  if (filterTitle == null || filterTitle === '') return true;
  return ska.name.toLowerCase().includes(filterTitle.toLowerCase());
};

const matchByInitiativeId = (
  ska: SprintKeyActivityTable__MItemFragment,
  filterInitiativeId: string | null
) => {
  if (filterInitiativeId == null || filterInitiativeId === '') return true;
  if (
    ska.supportsInitiatives2.some(
      (si) => si.initiative.id === filterInitiativeId
    )
  ) {
    return true;
  }
  if (
    ska.supportsMilestones.some((si) =>
      si.milestone.metadata.supports.some(
        (si) =>
          si.__typename === 'SupportedInitiative' &&
          si.item.id === filterInitiativeId
      )
    )
  ) {
    return true;
  }
  return false;
};

export const SprintKeyActivityTable = ({ initiativeId, filters }: Props) => {
  const { t } = useTranslation();

  const [selectedMitem, setSelectedMitem] =
    useState<SprintKeyActivityTable__MItemFragment | null>(null);

  const { data } = useSuspenseQuery(
    GetSprintKeyActivitiesForInitiativeDocument,
    {
      variables: { initiativeId },
    }
  );

  const sprintKeyActivities =
    data.sprintKeyActivitiesForInitiatives.oldSprintKAs.filter((m) => {
      if (!matchSprintKeyActivityByStatus(m, filters.status)) return false;
      if (!matchSprintKeyActivityByTitle(m, filters.title)) return false;
      if (!matchByInitiativeId(m, filters.initiativeId)) return false;
      return true;
    });

  const { data: permissionData, loading: loadingPermissions } =
    usePermissionForTeams(TeamResourceType.SPRINT_KA, Action.UPDATE);

  const permissionTeamMap =
    permissionData?.permittedToPerformActionForTeam.permittedResources &&
    toMap(
      permissionData.permittedToPerformActionForTeam.permittedResources,
      (item) => item.teamId
    );

  const milestoneColumns: ColumnProps<SprintKeyActivityTable__MItemFragment>[] =
    [
      {
        title: t('common.deadline'),
        width: 120,
        key: 'deadline',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => stringSort(a.deadline, b.deadline),
        dataIndex: ['deadline'],
        render: (deadline) => {
          return <div className="text-c">{friendlyDate(deadline)}</div>;
        },
      },
      {
        title: t('SprintKeyActivityTable.statusColumn'),
        key: 'status',
        dataIndex: ['status'],
        width: 150,
        render: (text, milestoneWithStats) => {
          const status =
            milestoneWithStats.status === MitemStatus.ACTIVE
              ? MitemStatus.PLANNED
              : milestoneWithStats.status;
          return <StatusTag status={status} showIcon />;
        },
      },
      {
        title: t('SprintKeyActivityTable.name'),
        key: 'name',
        dataIndex: ['name'],
        render: (text, ska) => {
          const initiatives = new Set<{
            title: string;
            iconId: string;
            colorCode: string;
          }>();

          ska.supportsMilestones.forEach((sm) => {
            sm.milestone.metadata.supports
              .filter((s) => s.__typename === 'SupportedInitiative')
              .forEach((s) => {
                initiatives.add(s.item.tag);
              });
          });
          ska.supportsInitiatives2.map((si) =>
            initiatives.add(si.initiative.tag)
          );

          return (
            <div>
              <div>{text}</div>
              <div className="txt--secondary h5">
                {[...initiatives].map((i) => i.title).join(', ')}
              </div>
            </div>
          );
        },
      },
      {
        title: t('common.actions'),
        dataIndex: 'action',
        key: 'action',
        width: 64,
        render: (_, mitem) => {
          if (loadingPermissions) return <Spin />;

          const isAllowedToEdit = !!permissionTeamMap?.[mitem.team.id];
          if (isAllowedToEdit) {
            return <EditSprintKeyActivityDrawerWithButton ska={mitem} />;
          }

          return (
            <Tooltip
              placement="top"
              title={t('SprintKeyActivityTable.noPermissionToEditSka')}
              mouseEnterDelay={0.7}
            >
              -
            </Tooltip>
          );
        },
      },
      {
        width: 64,
        render(_, record) {
          return (
            <Btn
              onClick={() => {
                setSelectedMitem(record);
              }}
              type="link"
              icon={<ManageSearchIcon style={{ fontSize: 16 }} />}
            />
          );
        },
      },
    ];

  return (
    <div>
      <Modal
        open={selectedMitem != null}
        onCancel={() => setSelectedMitem(null)}
        footer={null}
        modalRender={() =>
          selectedMitem && (
            <SprintKeyActivityDetails
              sprintKeyActivity={selectedMitem}
              onClose={() => setSelectedMitem(null)}
            />
          )
        }
      />
      <Table
        columns={milestoneColumns}
        dataSource={sprintKeyActivities}
        style={{ borderRadius: '6px' }}
        pagination={{ pageSize: 20 }}
        rowKey={(ska) => ska.id}
      />
    </div>
  );
};

SprintKeyActivityTable.Skeleton = SprintKeyActivityTableSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_KEY_ACTIVITY_TABLE__QUERY = gql`
  query getSprintKeyActivitiesForInitiative($initiativeId: ID!) {
    sprintKeyActivitiesForInitiatives(initiativeId: $initiativeId) {
      oldSprintKAs {
        id
        ...SprintKeyActivityTable__MItem
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_KEY_ACTIVITY_TABLE__MITEM = gql`
  fragment SprintKeyActivityTable__MItem on Mitem {
    id
    name
    team {
      id
      name
    }
    status
    deadline
    supportsInitiatives2 {
      id
      initiative {
        id
        name
        tag {
          title
          iconId
          colorCode
        }
      }
    }
    supportsMilestones {
      id
      milestone {
        id
        name
        metadata {
          status
          supports {
            ... on SupportedInitiative {
              item {
                id
                domainId {
                  itemId
                }
                name
                tag {
                  title
                  iconId
                  colorCode
                }
              }
            }
          }
        }
      }
    }
    ...EditSprintKeyActivityDrawerWithButton__Mitem
  }
`;
