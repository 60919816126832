import { Col, Row } from 'antd';
import { MilestoneOrActiveToggleEnum } from '../milestoneOrActivityToggle/MilestoneOrActiveToggle';

import { ProgessStatusFilterSkeleton } from './components/ProgessStatusFilter.Skeleton';
import { SprintKeyActivityTableSkeleton } from './components/milestoneTable/SprintKeyActivityTable.Skeleton';

export const ProgressSectionContentSkeleton = ({
  milestonesOrActivities,
}: {
  milestonesOrActivities: MilestoneOrActiveToggleEnum;
}) => {
  return (
    <div>
      <Row gutter={[18, 18]} className="mb--xl">
        <Col xs={12} xl={8}>
          <ProgessStatusFilterSkeleton status="completed" />
        </Col>
        <Col xs={12} xl={8}>
          <ProgessStatusFilterSkeleton status="overdue" />
        </Col>
        <Col xs={12} xl={8}>
          <ProgessStatusFilterSkeleton status="upcoming" />
        </Col>
      </Row>
      {milestonesOrActivities === MilestoneOrActiveToggleEnum.Milestones && (
        <SprintKeyActivityTableSkeleton />
      )}
      {milestonesOrActivities === MilestoneOrActiveToggleEnum.Activities && (
        <SprintKeyActivityTableSkeleton />
      )}
    </div>
  );
};
