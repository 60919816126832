import { useTranslation } from 'react-i18next';

import { useState } from 'react';
import { MilestoneOrActiveToggle } from '../milestoneOrActivityToggle/MilestoneOrActiveToggle';
import { Radio } from 'antd';

import { GantishSkeleton } from '../../../../../components/initiatives/Gantish/Gantish.skeleton';

enum OverviewSectionEnum {
  CurrentProgress,
  SubInitiatives,
}

const options = [
  { label: 'Current Progress', value: OverviewSectionEnum.CurrentProgress },
  { label: 'Sub-initiatives', value: OverviewSectionEnum.SubInitiatives },
];

export const OverviewSectionSkeleton = () => {
  const [overviewSectionSelection, setOverviewSectionSelection] = useState(
    OverviewSectionEnum.CurrentProgress
  );

  const { t } = useTranslation();

  return (
    <div>
      <h3 className="mb">{t('OverviewSection.title')}</h3>
      <div className="mb flx flx--jc-space-between flx--ai-center">
        <MilestoneOrActiveToggle.Skeleton />
        <Radio.Group
          disabled
          options={options}
          onChange={({ target: { value } }) =>
            setOverviewSectionSelection(value)
          }
          value={overviewSectionSelection}
          optionType="button"
        />
      </div>
      <div className="card mb--l">
        <GantishSkeleton />
      </div>
    </div>
  );
};
