import { Alert, Drawer, Form, Select, Typography } from 'antd';
import { useMemo, useState } from 'react';
import { useTenantDetails } from '../../../../../../../hooks/useTenantDetails';
import { usePermissionForTeams } from '../../../../../../../hooks/usePermissionForTeams';
import {
  Action,
  CreateSkaForTenantInitiativeDocument,
  GetSprintKeyActivitiesForInitiativeDocument,
  GetSprintKeyActivitiesForMilestoneDocument,
  GetTenantBusinessPlanInitiativeDocument,
  SprintKaInput,
  TeamResourceType,
} from '../../../../../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { MIG_ASSOCIATION_OTHER } from '../../../../../../team/sprint/planning/SprintPlanningPage';
import { standardDateFormat } from '../../../../../../../services/dateFormats';
import { gql, useMutation } from '@apollo/client';
import { InfoCircleOutlined } from '@ant-design/icons';
import { LearnMoreLink } from '../../../../../../../components/LearnMoreLink';
import { SprintKaForm } from '../../../../../../team/sprint/planning/components/SprintKaForm';
import { showNotification } from '../../../../../../../services/fetchNotificationProperties';
import { howweErrorParser } from '../../../../../../../services/howweErrorParser';
import { stringSort } from '../../../../../../../services/stringSort';
import { DrawerTitle } from '../../../../../../../components/DrawerTitle';

interface Props {
  contributingTeams: { id: string; name: string }[];
  initiativeId: string;
  open: boolean;
  onClose: () => void;
  milestone?: { id: string; domainId: { itemId: string } };
}

export const CreateSkaForTenantInitiativeDrawer = ({
  contributingTeams,
  initiativeId,
  open,
  milestone,
  onClose,
}: Props) => {
  const [teamId, setTeamId] = useState<string | undefined>();
  const { features } = useTenantDetails();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { data } = usePermissionForTeams(
    TeamResourceType.SPRINT_KA,
    Action.CREATE
  );

  const allowedTeams = useMemo(() => {
    return (
      contributingTeams.filter((ct) =>
        data?.permittedToPerformActionForTeam.permittedResources.some(
          (prt) => ct.id === prt.teamId
        )
      ) ?? []
    );
  }, [
    data?.permittedToPerformActionForTeam.permittedResources,
    contributingTeams,
  ]);

  const [createSkaForInitiative, { loading: createPending }] = useMutation(
    CreateSkaForTenantInitiativeDocument,
    {
      onCompleted: (data) => {
        showNotification('success', {
          message: t('CreateSkaForTenantInitiativeDrawer.saveSuccess'),
          description: data.createSprintKeyActivity.name,
          placement: 'top',
        });
        form.resetFields();
      },
      refetchQueries: () => [
        {
          query: GetSprintKeyActivitiesForInitiativeDocument,
          variables: { initiativeId },
        },
        {
          query: GetSprintKeyActivitiesForMilestoneDocument,
          skip: !milestone,
          variables: { milestoneId: milestone?.domainId.itemId },
        },
        {
          query: GetTenantBusinessPlanInitiativeDocument,
          variables: { initiativeId },
        },
      ],

      onError: (error) => {
        const howweErrors = howweErrorParser(error);

        showNotification('error', {
          message: t('CreateSkaForTenantInitiativeDrawer.saveError'),
          description: (
            <strong>
              <ul>
                {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
              </ul>
            </strong>
          ),
        });
      },
    }
  );

  const submit = (mitem: any, teamId: string) => {
    let tags = mitem.tags;
    let noMigAssociation: string | null | undefined = mitem.noMigAssociation;
    const {
      supportsMigId,
      supportsInitiativeIds,
      supportsMilestoneIds,
      ...rest
    } = mitem;
    const supportsInitiativeIdsToBeSubmitted =
      (supportsInitiativeIds as (string | null | undefined)[]) ?? [];

    const supportsMilestoneIdsToBeSubmitted =
      (supportsMilestoneIds as (string | null | undefined)[]) ?? [];

    const skaToSubmit: SprintKaInput = {
      ...rest,
      tags,
      supportsMigIds:
        supportsMigId === MIG_ASSOCIATION_OTHER || supportsMigId == null
          ? []
          : [{ id: supportsMigId }],
      noMigAssociation,
      deadline: standardDateFormat(mitem.deadline),
      supportsMilestoneIds: supportsMilestoneIdsToBeSubmitted
        .filter((i) => i != undefined)
        .filter((i) => i !== ''),
    };

    if (features.teamInitiativesEnabled) {
      skaToSubmit.supportsInitiativeIds = supportsInitiativeIdsToBeSubmitted
        .filter((i) => i != undefined)
        .filter((i) => i !== '');
    }

    if (features.tenantInitiativesEnabled) {
      skaToSubmit.supportsInitiative2Ids = supportsInitiativeIdsToBeSubmitted
        .filter((i) => i != undefined)
        .filter((i) => i !== '');
    }

    createSkaForInitiative({
      variables: {
        teamId,
        sprintKeyActivity: skaToSubmit,
      },
    });
  };

  const teamsOptions = allowedTeams.sort((a, b) => stringSort(a.name, b.name));

  const InfoArea = milestone ? (
    <Alert
      type="info"
      message={
        <>
          <Typography.Title level={4}>
            <InfoCircleOutlined className="mr" />
            {t('CreateSkaForTenantInitiativeDrawer.informationTitle')}
          </Typography.Title>
          <p>
            {t(
              'CreateSkaForTenantInitiativeDrawer.createRequirements.createSkaForMilestoneRequirements'
            )}

            <ul className="mt">
              <li className="mb--s">
                {t(
                  'CreateSkaForTenantInitiativeDrawer.createRequirements.permissionsRequirement'
                )}
              </li>
              <li>
                {t(
                  'CreateSkaForTenantInitiativeDrawer.createRequirements.joinedMilestoneRequirement'
                )}
              </li>
            </ul>
          </p>
        </>
      }
    ></Alert>
  ) : (
    <Alert
      type="info"
      message={
        <>
          <Typography.Title level={4}>
            <InfoCircleOutlined className="mr" />
            {t('CreateSkaForTenantInitiativeDrawer.informationTitle')}
          </Typography.Title>
          <p>
            {t('CreateSkaForTenantInitiativeDrawer.createRequirements.header')}

            <ul className="mt">
              <li className="mb--s">
                {t(
                  'CreateSkaForTenantInitiativeDrawer.createRequirements.permissionsRequirement'
                )}
                <div>
                  <LearnMoreLink urlTemplate="https://help.howwe.io/{{locale}}/articles/28924-team-leader-privileges" />
                </div>
              </li>
              <li>
                {t(
                  'CreateSkaForTenantInitiativeDrawer.createRequirements.joinedInitiativeRequirement'
                )}
                <div>
                  <LearnMoreLink urlTemplate="https://help.howwe.io/{{locale}}/articles/111024-joining-teams-to-company-initiatives" />
                </div>
              </li>
            </ul>
          </p>
        </>
      }
    ></Alert>
  );

  return (
    <div>
      <Drawer
        title={
          <DrawerTitle className="CreateMitemDrawer__title">
            {t('CreateSkaForTenantInitiativeDrawer.modalTitle')}
          </DrawerTitle>
        }
        open={open}
        onClose={() => {
          setTeamId(undefined);
          onClose();
        }}
        width={420}
        destroyOnClose
      >
        <h4 className="bold mb--s">{'Team Selector'}</h4>

        <Form.Item
          name="name"
          data-intercom-target="Sprint Key Activity Form Name Input"
        >
          <Select
            showSearch
            allowClear
            value={teamId}
            placeholder={t('common.searchTeamPlaceholder')}
            optionFilterProp="children"
            onChange={(value) => {
              form.resetFields();
              setTeamId(value);
            }}
            options={teamsOptions.map((t) => ({
              value: t.id,
              label: t.name,
            }))}
            filterOption={(input, option) =>
              (option?.label as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          />
        </Form.Item>

        {!teamId && InfoArea}

        {teamId && (
          <SprintKaForm
            key={teamId}
            teamId={teamId}
            isEdit={false}
            onSubmit={(ska) => submit(ska, teamId)}
            submitPending={createPending}
            initialValues={{
              supportsMilestones: milestone ? [{ id: milestone.id }] : [],
            }}
            onCancel={() => {
              form.resetFields();
              onClose();
            }}
            formRef={form}
          />
        )}
      </Drawer>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CREATE_SKA_FOR_INITIATIVE = gql`
  mutation createSkaForTenantInitiative(
    $teamId: ID!
    $sprintKeyActivity: SprintKaInput!
  ) {
    createSprintKeyActivity(
      teamId: $teamId
      sprintKeyActivity: $sprintKeyActivity
    ) {
      ...CreateSprintKeyActivityDrawerWithButton__Mitem
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CreateSprintKeyActivityForInitiativeFragment = gql`
  fragment CreateSprintKeyActivityForInitiative__Mitem on Mitem {
    id
    name
    archived
    archivedAt
    completed
    completedAt
    deadline
    definitionOfDone
    noMigAssociation
    milestone
    supportedInitiatives {
      id
      tag {
        title
        iconId
        colorCode
      }
    }
    supportsInitiatives2 {
      id
      initiative {
        tag {
          title
          iconId
          colorCode
        }
      }
    }
    status
    tags {
      id
      name
      teamId
      active
      backgroundColor
      createdAt
    }
    owner {
      id
      email
      name
      displayName
      archivedAt
    }
    supportedMigs {
      id
      domainId {
        itemId
        teamId
      }
      name
    }
    teamId
  }
`;
