import { Form } from 'antd';
import { InitiativeSelector } from './InitiativeSelector';
import { Btn } from '../../../../../../components/Button';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useTeamInitiatives } from '../../../../../../hooks/useTeamInitiatives';

import { useTranslation } from 'react-i18next';
import { atLeastOne } from '../../../../../../services/error-parser/helpers';

interface Props {
  teamId: string;
  selectedInitiativeIds: string[];
  initialSupportInitiativeIds?: string[];
}

export const MitemFormTeamInitiativeFormItem = ({
  teamId,

  selectedInitiativeIds,
  initialSupportInitiativeIds,
}: Props) => {
  const { t } = useTranslation();
  const { teamInitiatives } = useTeamInitiatives(teamId);

  const selectableTeamInitiatives = teamInitiatives?.filter(
    (ti) =>
      (ti.archived === false && ti.completed.value === false) ||
      initialSupportInitiativeIds?.some((id) => id === ti.id)
  );

  const teamHasInitiatives = atLeastOne(teamInitiatives);

  return (
    <Form.Item label={t('MitemForm.initiative')}>
      <Form.List name="supportsInitiativeIds">
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field, index) => (
                <div className="flx" key={field.key + index}>
                  <Form.Item className="flx--1" {...field}>
                    <InitiativeSelector
                      initiatives={selectableTeamInitiatives ?? []}
                      selectedInitiativeIds={selectedInitiativeIds}
                      className={
                        index > 0 ? 'SprintKaForm__initiativeSelector' : ''
                      }
                      disabled={!teamHasInitiatives}
                      placeHolder={
                        !teamHasInitiatives
                          ? t('MitemForm.noInitiativesAvailable')
                          : undefined
                      }
                    />
                  </Form.Item>
                  {index > 0 && (
                    <Btn
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  )}
                </div>
              ))}
              <Form.Item>
                <Btn
                  type="link"
                  style={{ padding: 0 }}
                  icon={<PlusOutlined />}
                  disabled={
                    !teamHasInitiatives ||
                    teamInitiatives?.length === selectedInitiativeIds?.length
                  }
                  onClick={() => {
                    add();
                  }}
                >
                  {t('MitemForm.addInitiative')}
                </Btn>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    </Form.Item>
  );
};
