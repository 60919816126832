import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { atLeastOne } from '../../../../../../../services/error-parser/helpers';
import { GigantishTreeNode } from '../../Gigantish';
import cx from 'classnames';

import './GigantishLegendInitiative.less';
import { InitiativeValue } from './components/InitiativeValue';
import { GigantishLegendInitiativeSkeleton } from './GigantishLegendInitiative.skeleton';

interface Props {
  initiativeNode: GigantishTreeNode;
  expandedInitiatives: string[];
  isSubInitiative?: boolean;
  onToggle: (id: string) => void;
}

export const GigantishLegendInitiative = ({
  initiativeNode,
  expandedInitiatives,
  isSubInitiative = false,
  onToggle,
}: Props) => {
  const hasChildren = atLeastOne(initiativeNode.children);
  const isExpanded = expandedInitiatives.includes(initiativeNode.id);
  const status = initiativeNode.data.initiative.metadata.status;
  return (
    <div className="GigantishLegendInitiative gantishShared-AppendAnimation">
      <div
        key={initiativeNode.id}
        className={cx('GigantishLegendInitiative__row', {
          'GigantishLegendInitiative__row--small': isSubInitiative,
        })}
      >
        {hasChildren && (
          <div
            className="GigantishLegendInitiative__toggle"
            onClick={() => onToggle(initiativeNode.id)}
          >
            {isExpanded ? <MinusSquareOutlined /> : <PlusSquareOutlined />}
          </div>
        )}
        {initiativeNode.baseInitiative.name}
        <div className="GigantishLegendInitiative__value">
          <InitiativeValue
            value={initiativeNode.data.initiative.profit}
            deprecatedStatus={status}
            archived={initiativeNode.data.initiative.metadata.archived}
          />
          <InitiativeValue
            value={initiativeNode.data.initiative.revenue}
            deprecatedStatus={status}
            archived={initiativeNode.data.initiative.metadata.archived}
          />
        </div>
      </div>
      {isExpanded && (
        <div>
          {initiativeNode.children?.map((subInitiative) => (
            <GigantishLegendInitiative
              key={subInitiative.id}
              initiativeNode={subInitiative}
              expandedInitiatives={expandedInitiatives}
              isSubInitiative={true}
              onToggle={onToggle}
            />
          ))}
        </div>
      )}
    </div>
  );
};

GigantishLegendInitiative.skeleton = GigantishLegendInitiativeSkeleton;
