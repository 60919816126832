import { useTranslation } from 'react-i18next';
import { MilestoneActivitiesTable } from '../../MilestoneActivitiesTable';
import { MilestoneCardActivityTabSkeleton } from './MilestoneCardActivityTab.Skeleton';
import { MilestoneCardActivityTabError } from './MilestoneCardActivityTab.Error';

interface Props {
  milestoneId: string;
  teamId: string;
}

export const MilestoneCardActivityTab = ({ milestoneId, teamId }: Props) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="mt--l">
        <h4 className="mb">{t('MilestoneCardActivityTab.yourTeam')}</h4>
        <MilestoneActivitiesTable
          milestoneItemId={milestoneId}
          filterPredicate={(mitem) => mitem.teamId === teamId}
        />
      </div>
      <div className="mt--xl">
        <h4 className="mb">{t('MilestoneCardActivityTab.otherTeams')}</h4>
        <MilestoneActivitiesTable
          milestoneItemId={milestoneId}
          filterPredicate={(mitem) => mitem.teamId !== teamId}
        />
      </div>
    </div>
  );
};

MilestoneCardActivityTab.Skeleton = MilestoneCardActivityTabSkeleton;
MilestoneCardActivityTab.Error = MilestoneCardActivityTabError;
