import { Divider } from 'antd';
import { Btn } from '../../../../Button';
import { gql, useMutation, useSuspenseQuery } from '@apollo/client';

import { useTranslation } from 'react-i18next';
import { howweErrorParser } from '../../../../../services/howweErrorParser';
import { showNotification } from '../../../../../services/fetchNotificationProperties';
import {
  ReactivateInitiativeModalContent_MutationDocument,
  ReactivateInitiativeModalContent_QueryDocument,
} from '../../../../../generated/graphql';
import { ReactivateInitiativeModalContentSkeleton } from './ReactivateInitiativeModalContent skeleton';

interface Props {
  initiativeId: string;
  onClose: () => void;
}

export const ReactivateInitiativeModalContent = ({
  initiativeId,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const { data } = useSuspenseQuery(
    ReactivateInitiativeModalContent_QueryDocument,
    { variables: { initiativeId: initiativeId }, fetchPolicy: 'network-only' }
  );

  const [reactivate, { loading }] = useMutation(
    ReactivateInitiativeModalContent_MutationDocument,
    {
      onError: (error) => {
        const howweErrors = howweErrorParser(error);

        showNotification('error', {
          message: t('ReactivateInitiativeModalContent.saveError'),
          description: (
            <strong>
              <ul>
                {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
              </ul>
            </strong>
          ),
        });
      },
      onCompleted: () => {
        showNotification('success', {
          message: t('ReactivateInitiativeModalContent.saveSuccess'),
        });
        onClose();
      },
    }
  );

  const handleReactivate = () => {
    reactivate({
      variables: {
        initiativeId: initiativeId,
        initiative: {
          completed: false,
          rev: data.tenantInitiative.rev,
        },
      },
    });
  };

  return (
    <div className="text-c flx flx--column">
      <h3>{t('ReactivateInitiativeModalContent.title')}</h3>
      {data.tenantInitiative.name}

      <Divider />
      <div>
        <Btn loading={loading} onClick={handleReactivate} type="primary">
          {t('ReactivateInitiativeModalContent.reactivate')}
        </Btn>
      </div>
    </div>
  );
};

ReactivateInitiativeModalContent.Skeleton =
  ReactivateInitiativeModalContentSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EDIT_INITIATIVE = gql`
  mutation reactivateInitiativeModalContent_mutation(
    $tenantId: ID
    $initiativeId: ID!
    $initiative: UpdateInitiative2Input!
  ) {
    updateTenantInitiative(
      tenantId: $tenantId
      initiativeId: $initiativeId
      initiative: $initiative
    ) {
      id
      name
      rev
      metadata {
        completedAt
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_INITIATIVE = gql`
  query reactivateInitiativeModalContent_query(
    $tenantId: ID
    $initiativeId: ID!
  ) {
    tenantInitiative(tenantId: $tenantId, initiativeId: $initiativeId) {
      id
      name
      rev
      metadata {
        completedAt
      }
    }
  }
`;
